import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

const Transactions = ({ setLoading, getConfig, loading, config, show }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        // Honestly, don't even ask. I have no fucking idea.
        resetField('transactions', {
            defaultValue: JSON.parse(JSON.stringify(config?.transactions || {}))
        });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        for(const type in updatedConfig.transactions) {
            for(const key in updatedConfig.transactions[ type ]) {
                if(typeof updatedConfig.transactions[ type ][ key ] === 'boolean') {
                    if(updatedConfig.transactions[ type ][ key ] !== config.transactions[ type ][ key ])
                        parameters[ `${ type }.${ key }` ] = updatedConfig.transactions[ type ][ key ];

                    continue;
                }

                for(const subKey in updatedConfig.transactions[ type ][ key ]) {
                    if(updatedConfig.transactions[ type ][ key ][ subKey ] !== config.transactions[ type ][ key ][ subKey ])
                        parameters[ `${ type }.${ key }.${ subKey }` ] = updatedConfig.transactions[ type ][ key ][ subKey ];
                }
            }
        }

        setLoading(true);

        api
            .setConfigTransactions(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    const transactions = watch('transactions'); // Again, don't even ask.
    const isDirty = JSON.stringify(config?.transactions) !== JSON.stringify(transactions);

    return (
        <div className={ !(show) ? 'd-none' : '' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Transactions
                </h3>
            </div>
            <div className='card-body transactions'>
                <div className='row d-flex align-items-center mb-2'>
                    <h3 className='card-title col-3 mb-0'>
                        Crypto methods
                    </h3>
                    <span className='text-center col'>
                        Deposits
                    </span>
                    <span className='text-center col'>
                        Withdraws
                    </span>
                </div>
                { Object.keys(config?.transactions?.deposit?.crypto || {}).map(currency => (
                    <div className='form-group row' key={ currency }>
                        <label className='form-label col-3 col-form-label'>
                            { currency }
                        </label>
                        <div className='col text-center'>
                            <label className='form-check form-switch'>
                                <input
                                    className='form-check-input'
                                    disabled={ loading }
                                    type='checkbox'
                                    { ...register(`transactions.deposit.crypto.${ currency }`) }
                                />
                            </label>
                        </div>
                        <div className='col text-center'>
                            <label className='form-check form-switch'>
                                <input
                                    className='form-check-input'
                                    disabled={ loading }
                                    type='checkbox'
                                    { ...register(`transactions.withdraw.crypto.${ currency }`) }
                                />
                            </label>
                        </div>
                    </div>
                )) }
            </div>
            <div className='card-body transactions'>
                <div className='row d-flex align-items-center mb-2'>
                    <h3 className='card-title col-3 mb-0'>
                        Peer trades
                    </h3>
                    <span className='text-center col'>
                        Deposits
                    </span>
                    <span className='text-center col'>
                        Withdraws
                    </span>
                </div>
                { Object.keys(config?.transactions?.deposit?.peer || {}).map(game => (
                    <div className='form-group row' key={ game }>
                        <label className='form-label col-3 col-form-label'>
                            { game }
                        </label>
                        <div className='col text-center'>
                            <label className='form-check form-switch'>
                                <input
                                    className='form-check-input'
                                    disabled={ loading }
                                    type='checkbox'
                                    { ...register(`transactions.deposit.peer.${ game }`) }
                                />
                            </label>
                        </div>
                        <div className='col text-center'>
                            <label className='form-check form-switch'>
                                <input
                                    className='form-check-input'
                                    disabled={ loading }
                                    type='checkbox'
                                    { ...register(`transactions.withdraw.peer.${ game }`) }
                                />
                            </label>
                        </div>
                    </div>
                )) }
            </div>
            <div className='card-body transactions'>
                <div className='row d-flex align-items-center mb-2'>
                    <h3 className='card-title col-3 mb-0'>
                        Waxpeer
                    </h3>
                    <span className='text-center col text-muted'>
                        Deposits
                    </span>
                    <span className='text-center col'>
                        Withdraws
                    </span>
                </div>
                { Object.keys(config?.transactions?.withdraw?.waxpeer || {}).map(game => (
                    <div className='form-group row' key={ game }>
                        <label className='form-label col-3 col-form-label'>
                            { game }
                        </label>
                        <div className='col text-center'>
                            <label className='form-check form-switch'>
                                <input
                                    className='form-check-input'
                                    disabled={ true }
                                    type='checkbox'
                                />
                            </label>
                        </div>
                        <div className='col text-center'>
                            <label className='form-check form-switch'>
                                <input
                                    className='form-check-input'
                                    disabled={ loading }
                                    type='checkbox'
                                    { ...register(`transactions.withdraw.waxpeer.${ game }`) }
                                />
                            </label>
                        </div>
                    </div>
                )) }
            </div>
            <div className='card-body transactions'>
                <div className='row d-flex align-items-center mb-2'>
                    <h3 className='card-title col-3 mb-0'>
                        Miscellaneous
                    </h3>
                    <span className='text-center col'>
                        Deposits
                    </span>
                    <span className='text-center col text-muted'>
                        Withdraws
                    </span>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        G2A
                    </label>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('transactions.deposit.g2apay') }
                            />
                        </label>
                    </div>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ true }
                                type='checkbox'
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Gamemoney
                    </label>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('transactions.deposit.gamemoney') }
                            />
                        </label>
                    </div>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ true }
                                type='checkbox'
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Skincrib
                    </label>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('transactions.deposit.skincrib') }
                            />
                        </label>
                    </div>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ true }
                                type='checkbox'
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Waxpeer
                    </label>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('transactions.deposit.waxpeer') }
                            />
                        </label>
                    </div>
                    <div className='col text-center'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ true }
                                type='checkbox'
                            />
                        </label>
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Transactions;