export const generateChartOptions = () => ({
    chart: {
        parentHeightOffset: 0,
        fontFamily: 'inherit',
        animations: { enabled: false },
        toolbar: { show: false },
        stacked: true,
        height: 240,
        type: 'area'
    },
    dataLabels: { enabled: false, },
    fill: { opacity: .16, type: 'solid' },
    stroke: {
        width: 2,
        lineCap: 'round',
        curve: 'smooth',
    },
    grid: {
        padding: {
            top: -20,
            right: 0,
            left: -4,
            bottom: -4
        },
        strokeDashArray: 4,
    },
    xaxis: {
        axisBorder: { show: false, },
        tooltip: { enabled: false },
        labels: { padding: 0, },
        type: 'datetime'
    },
    yaxis: {
        labels: {
            padding: 4
        },
    },
    legend: { show: false }
});

export const generateCurrencyFormatter = () => ({
    yaxis: {
        labels: {
            formatter: value => `$${ Number(value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) }`,
            padding: 4
        },
    },
});