import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

const Affiliates = ({ setLoading, getConfig, loading, config, show, siteConfig }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('affiliateCurrency', { defaultValue: config.affiliateCurrency });
        resetField('affiliateReward', { defaultValue: config.affiliateReward / 100 });
        resetField('affiliateRate', { defaultValue: config.affiliateRate });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        if(updatedConfig.affiliateCurrency !== config.affiliateCurrency)
            parameters.currency = updatedConfig.affiliateCurrency;

        if(updatedConfig.affiliateRate !== config.affiliateRate)
            parameters.rate = updatedConfig.affiliateRate;

        if((updatedConfig.affiliateReward * 100) !== config.affiliateReward)
            parameters.reward = updatedConfig.affiliateReward * 100;

        setLoading(true);

        api
            .setConfigAffiliates(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    return (
        <div className={ !(show) ? 'd-none' : '' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Affiliates
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Redemption reward
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                $
                            </span>
                            <input
                                placeholder='0.5'
                                className={ `form-control ps-0 ${ formState.errors.affiliateReward ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('affiliateReward', {
                                    pattern: /^\d{0,2}(\.\d{1,2})?$/,
                                    min: 0.01,
                                    max: 10
                                }) }
                            />
                        </div>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.affiliateReward?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Amount users are rewarded when redeeming an affiliate code
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Redemption currency
                    </label>
                    <div className='col'>
                        <select
                            className={ `form-select form-control ${ formState.errors.affiliateCurrency ? 'is-invalid' : '' }` }
                            disabled={ loading }
                            { ...register('affiliateCurrency') }
                        >
                            { Object.keys(siteConfig.currencies).map(currency => (
                                <option value={ currency } key={ currency }>
                                    { currency}
                                </option>
                            )) }
                        </select>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.affiliateCurrency?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Reward currency when redeeming an affiliate code
                            </small>
                        ) }
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Payout percentage
                    </label>
                    <div className='col'>
                        <input
                            placeholder='0.15'
                            className={ `form-control ${ formState.errors.affiliateRate ? 'is-invalid' : '' }` }
                            disabled={ loading }
                            type='text'
                            { ...register('affiliateRate', {
                                required: true,
                                pattern: /^0\.([1-9]\d?|0[1-9]+)$/,
                                min: 0.01,
                                max: 0.15
                            }) }
                        />
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.affiliateRate?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Percentage of user wagers paid to affiliate owners
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(formState.isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    siteConfig: state.app.config
}))(Affiliates);