import React from 'react';
import api from 'api';
import Pagination from 'components/ui/Pagination';
import formatDate from 'dateformat';
import CreatePromoCode from './modals/CreatePromoCode';
import DeletePromoCode from './modals/DeletePromoCode';
import ViewPromoCode from './modals/ViewPromoCode';

import { useEffect, useState, useCallback } from 'react';
import { IconX } from '@tabler/icons';
import { connect } from 'react-redux';
import { Permissions, hasPermissions } from 'utils/permissions';

import './PromoCodes.scss';

const DEFAULT_PAGINATION = {
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    total: 0,
    from: 0,
    to: 0
};

const PromoCodes = ({ site, user }) => {
    const [ inactivePromoCodes, setInactivePromoCodes ] = useState([]);
    const [ activePromoCodes, setActivePromoCodes ] = useState([]);
    const [ pagination, setPagination ] = useState(DEFAULT_PAGINATION);
    const [ loading, setLoading ] = useState(true);
    const [ modal, setModal ] = useState(false);
    const [ error, setError ] = useState(false);

    const getPromoCodes = useCallback(async (shouldFetchActive, page) => {
        setLoading(true);

        api
            .getPromoCodes(false, page)
            .then(({ data, pagination }) => {
                return [ data, pagination ];
            })
            .then(async ([ inactive, pagination ]) => {
                return [
                    inactive,
                    pagination,
                    shouldFetchActive && await api.getPromoCodes(true)
                ];
            })
            .then(([ inactive, pagination, active = false ]) => {
                setLoading(false);

                if(active)
                    setActivePromoCodes(active);

                setInactivePromoCodes(inactive);
                setPagination(pagination);
            })
            .catch(error => {
                console.warn('Failed to fetch promo codes');
                console.error(error);

                setInactivePromoCodes([]);
                setActivePromoCodes([]);
                setPagination(DEFAULT_PAGINATION);
                setLoading(false);
            });
    }, [ ]);

    useEffect(() => {
        getPromoCodes(true, 1);
    }, [ site ]);

    const onCloseModal = useCallback(() => {
        if(loading)
            return;

        setModal(false);
    }, [ loading ]);

    const onCreate = useCallback(({ currency, reward, users, name }) => {
        setLoading(true);

        return api
            .createPromoCode({
                currency,
                reward,
                users,
                name
            })
            .then(() => {
                return getPromoCodes(true, pagination.currentPage);
            })
            .then(() => {
                setModal(false);
            })
            .catch(error => {
                console.warn('Failed to create promo code');
                console.error(error);

                switch (error) {
                    case 'INVALID_PROMO_REWARD': setError('Invalid reward amount provided'); break;
                    case 'INVALID_PROMO_USERS': setError('Invalid user count provided'); break;
                    case 'INVALID_PROMO_CODE': setError('Invalid promo code name provided'); break;
                    case 'PROMO_CODE_EXISTS': setError('Provided promo code name is already in use'); break;
                    case 'INVALID_CURRENCY': setError('Invalid currency provided'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
            });
    }, [ pagination.currentPage ]);

    const onDelete = useCallback(() => {
        setLoading(true);

        api
            .cancelPromoCode(modal.substr(7))
            .then(() => {
                return getPromoCodes(true, pagination.currentPage);
            })
            .then(() => {
                setModal(false);
            })
            .catch(error => {
                console.warn('Failed to cancel promo code');
                console.error(error);

                switch (error) {
                    case 'INVALID_PROMO_CODE': setError('Promo code does not exist'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
            });
    }, [ modal, pagination.currentPage ]);

    return (
        <>
            <div className='container-xl'>
                <div className='page-header'>
                    <div className='row g-2 align-items-center'>
                        <div className='col'>
                            <h2 className='page-title'>
                                Promo codes
                            </h2>
                        </div>
                        <div className='col-12 col-md-auto ms-auto'>
                            { hasPermissions(user?.permissions?.all || 0, Permissions.CAN_MODIFY_PROMO_CODE) ? (
                                <button
                                    className='btn btn-primary'
                                    disabled={ loading }
                                    onClick={ () => setModal('create') }
                                >
                                    Create promo code
                                </button>
                            ) : '' }
                        </div>
                    </div>
                </div>
            </div>
            <div className='page-body'>
                <div className='container-xl'>
                    <div className='row row-cards'>
                        { activePromoCodes.map(promoCode => (
                            <div className='col-md-6 col-lg-4' key={ promoCode.promoCode }>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className={ 'card-title mb-1' }>
                                            <div className='d-flex align-items-center'>
                                                <span className='role-name'>
                                                    { promoCode.promoCode }
                                                </span>
                                                <div className='card-actions btn-actions'>
                                                    { hasPermissions(user?.permissions?.all || 0, Permissions.CAN_MODIFY_PROMO_CODE) ? (
                                                        <a
                                                            className='btn-action text-red'
                                                            onClick={ () => setModal(`delete-${ promoCode.promoCode }`)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <IconX />
                                                        </a>
                                                    ) : '' }
                                                </div>
                                            </div>
                                        </div>
                                        <ul className='list-unstyled'>
                                            <li>
                                                ${ Number(promoCode.reward / 100).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }) } { promoCode.currency }
                                            </li>
                                            <li>
                                                { promoCode.userCount } of { promoCode.maxUsers } users
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )) }
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h3 className='card-title'>
                                        Expired promo codes
                                    </h3>
                                </div>
                                <div className='card-table table-responsive'>
                                    <table className='table table-vcenter'>
                                        <thead>
                                            <tr>
                                                <th>Promo code</th>
                                                <th>Created at</th>
                                                <th>Reward</th>
                                                <th>Redemptions</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className='placeholder-glow'>
                                            { (loading && !(modal) && !(inactivePromoCodes.length)) ? Array.from({ length: 10 }).map((_, index) => (
                                                <tr key={ index }>
                                                    <td className='user' style={{ minWidth: '5em' }}>
                                                        <span className='avatar avatar-sm me-3 placeholder' />
                                                    </td>
                                                    <td style={{ minWidth: '10em' }}>
                                                        <span className='placeholder placeholder-xs col-12' />
                                                    </td>
                                                    <td className='td-truncate'>
                                                        <div className='text-truncate'>
                                                            <code className='placeholder placeholder-xs col-9' />
                                                        </div>
                                                    </td>
                                                    <td className='text-nowrap text-muted' style={{ minWidth: '5em' }}>
                                                        <span className='placeholder placeholder-xs col-12' />
                                                    </td>
                                                    <td className='text-nowrap text-muted' style={{ minWidth: '5em' }}>
                                                        <span className='placeholder placeholder-xs col-12' />
                                                    </td>
                                                </tr>
                                            )) : inactivePromoCodes.length ? inactivePromoCodes.map(promoCode => (
                                                <tr key={ promoCode.promoCodeID }>
                                                    <td>
                                                        { promoCode.promoCodeID }
                                                    </td>
                                                    <td>
                                                        { formatDate(promoCode.createdAt, 'mmmm dd, yyyy') }
                                                    </td>
                                                    <td>
                                                        ${ Number(promoCode[ 'reward:amount' ] / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }) } { promoCode[ 'reward:currency' ] }
                                                    </td>
                                                    <td>
                                                        { promoCode.users } of { promoCode.maxUsers }
                                                    </td>
                                                    <td className='text-end'>
                                                        <button
                                                            className='btn btn-ghost-primary'
                                                            disabled={ !(promoCode.users) }
                                                            onClick={ () => setModal(`view-${ promoCode.promoCodeID }`)}
                                                        >
                                                            View users
                                                        </button>
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td className='text-center' colSpan={ 5 } style={{ padding: '2rem' }}>
                                                        No expired promo codes found
                                                    </td>
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='card-footer d-flex align-items-center'>
                                    <p className='m-0 text-muted'>
                                        Showing <strong>
                                            { pagination.to ? (pagination.from + 1) : 0 }
                                        </strong> to <strong>
                                            { pagination.to }
                                        </strong> of <strong>
                                            { pagination.total }
                                        </strong> entries
                                    </p>
                                    <Pagination
                                        onPageChange={ page => getPromoCodes(false, page) }
                                        currentPage={ pagination.currentPage }
                                        totalCount={ pagination.total }
                                        pageSize={ pagination.perPage }
                                        disabled={ loading }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { modal === 'create' ? (
                <CreatePromoCode
                    activePromoCodes={ activePromoCodes }
                    onCloseModal={ onCloseModal }
                    onCreate={ onCreate }
                    loading={ loading }
                    error={ error }
                />
            ) : (modal && modal.startsWith('delete')) ? (
                <DeletePromoCode
                    onCloseModal={ onCloseModal }
                    promoCode={ modal.substr(7) }
                    onDelete={ onDelete }
                    loading={ loading }
                    error={ error }
                />
            ) : (modal && modal.startsWith('view')) ? (
                <ViewPromoCode
                    onCloseModal={ onCloseModal }
                    promoCode={ inactivePromoCodes.filter(promoCode => promoCode.promoCodeID === modal.substr(5))[ 0 ] }
                    site={ site }
                />
            ) : '' }
        </>
    );
};

export default connect(state => ({
    user: state.app.user
}))(PromoCodes);