import Logger from 'utils/Logger.js';
import EventEmitter from 'eventemitter3';
import parseName from 'csgo-item-name-parser';

import { hasPermissions, Permissions } from 'utils/permissions';
import { decodeJwt, request } from 'utils';

import 'ews-client';

const logger = new Logger('api');

class API extends EventEmitter {
    // Websocket
    connected = false;
    socket = false;
    token = false;
    host = false;

    // HTTP Server
    refreshToken = false;
    authToken = false;
    interval = false;

    setHost(host) {
        this.host = host;
        return this;
    }

    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
        return this;
    }

    storeAuthToken(token) {
        try {
            const decoded = decodeJwt(token);

            if(decoded.exp)
                decoded.exp *= 1000;

            if(!decoded?.exp || ((decoded.exp * 1000) - 30_000) < Date.now())
                return;

            clearInterval(this.interval);

            this.authToken = {
                expiresAt: decoded.exp,
                token,
            };

            this.interval = setInterval(() => {
                this.refreshAuthToken();
            }, (decoded.exp - 30_000) - Date.now());
        } catch{}
    }

    async refreshAuthToken() {
        const {
            refreshToken,
            authToken
        } = await request({
            method: 'POST',
            token: this.refreshToken,
            url: `${ this.host }/auth/refresh`,
        }).catch(error => {
            logger.warn('Failed to refresh auth token', error);
            return false;
        });

        if(!refreshToken)
            return false;

        this.storeAuthToken(authToken);
        this.emit('token', refreshToken);
        this.refreshToken = refreshToken;

        return authToken;
    }

    getAuthToken() {
        if(this.authToken?.expiresAt >= (Date.now() + 30_000))
            return this.authToken.token;

        return this.refreshAuthToken();
    }

    async get(endpoint, data) {
        const token = await this
            .getAuthToken();

        return request({
            url: `${ this.host }/${ endpoint }`,
            token,
            data
        }).then(data => {
            return { data };
        }).catch(error => {
            return { error };
        });
    }

    async post(endpoint, data) {
        const token = await this
            .getAuthToken();

        return request({
            method: 'POST',
            url: `${ this.host }/${ endpoint }`,
            token,
            data
        }).then(data => {
            return { data };
        }).catch(error => {
            return { error };
        });
    }

    async connect() {
        if(this.socket) {
            this.socket.removeAllListeners();
            this.socket.close();
        }

        const host = `ws${ this.host.substr(4) }/socket`;
        const authToken = await this
            .getAuthToken();

        logger.info(`Connecting to ${ host }`);

        const socket = new window.WebSocketClient(host, authToken, {
            __deprecatedEventStructure: true,
            timeoutInterval: 2000,
            reconnect: false
        });

        socket.on('open', async () => {
            logger.info(`Connected to ${ host } with${ authToken ? '' : 'out' } authentication`);
            socket.send('subscribe', 'auth');
        });

        socket.on('app.config', config => {
            this.emit('config', config);
        });

        socket.on('app.user', user => {
            if(!user) {
                logger.info('Connected to backend, not authenticated');
                return this.emit('connected');
            }

            if(!(hasPermissions(user.permissions.all, Permissions.CAN_VIEW_ADMIN_PANEL))) {
                this.emit('connected', { user });
                return this.emit('error', 'Access forbidden');
            }

            this.subscribe('admin').then(() => {
                return this.request('admin.getFeatures');
            }).then(({ data: features }) => {
                this.emit('connected', {
                    features,
                    user
                });
            }).catch(error => {
                this.emit('error', 'Access forbidden');
            });
        });

        socket.on('close', event => {
            logger.warn('Disconnected from socket');
            logger.error(event?.reason || event?.code);

            this.connected = false;
            this.socket = false;

            socket.removeAllListeners();
            this.emit('disconnected');
        });

        this.socket = socket;
        window.__socket = socket;
    }

    disconnect() {
        if(!(this.socket))
            return;

        this.socket.close();
        this.socket.emit('close');
    }

    subscribe(namespace) {
        return new Promise((resolve, reject) => {
            this.socket.send('subscribe', namespace, ({ error, data }) => {
                if(error)
                    return reject(error);

                resolve(data);
            });
        });
    }

    request(event, data) {
        return new Promise(resolve => {
            this.socket.send(event, data, args => {
                if(args === 'Socket closed')
                    return resolve({ error: 'Connection lost' });

                const { error, data } = args;

                if(error)
                    return resolve({ error });

                resolve({ data });
            });
        });
    }

    async sha256(message) {
        const buffer = new TextEncoder('utf-8').encode(message);
        const hash = await crypto.subtle.digest('SHA-256', buffer);

        return Array
            .from(new Uint8Array(hash))
            .map(byte => (
                byte
                    .toString('16')
                    .padStart(2, '0')
            ))
            .join('');
    }

    async authenticate({ twoFactorCode, password, username, captcha, site }) {
        const {
            error,
            data: {
                refreshToken,
                authToken
            } = {}
        } = await this.request('auth.login', {
            twoFactorCode: twoFactorCode.length ? twoFactorCode : undefined,
            password: await this.sha256(password),
            username,
            captcha
        });

        if(error)
            throw error;

        localStorage.setItem(`auth.${ site }`, refreshToken);

        this.setRefreshToken(refreshToken);
        this.storeAuthToken(authToken);

        this.socket.removeAllListeners();
        this.socket.close();

        this.connect();
    }

    async getSystemLogs(page, username = false) {
        const {
            error,
            data
        } = await this.request('admin.listLogHistory', {
            subject: username,
            page
        });

        if(error)
            throw error;

        return data;
    }

    async getUserList(page, username = false) {
        const {
            error,
            data
        } = await this.request('admin.listUsers', {
            username,
            page
        });

        if(error)
            throw error;

        return data;
    }

    async getRoles() {
        const {
            error,
            data
        } = await this.request('admin.listRoles');

        if(error)
            throw error;

        return data.roles;
    }

    async deleteRole(role) {
        const {
            error
        } = await this.request('admin.deleteRole', {
            role
        });

        if(error)
            throw error;
    }

    async updateRole(role, inherits, permissions) {
        const {
            error
        } = await this.request('admin.editRole', {
            permissions,
            inherits,
            role
        });

        if(error)
            throw error;
    }

    async createRole(role, inherits, permissions) {
        const {
            error
        } = await this.request('admin.createRole', {
            permissions,
            inherits,
            role
        });

        if(error)
            throw error;
    }

    async getFlairs() {
        const {
            error,
            data
        } = await this.request('admin.listFlairs');

        if(error)
            throw error;

        return data.flairs;
    }

    async createFlair(flair) {
        const {
            error
        } = await this.request('admin.createFlair', {
            flair
        });

        if(error)
            throw error;
    }

    async deleteFlair(flair) {
        const {
            error
        } = await this.request('admin.deleteFlair', {
            flair
        });

        if(error)
            throw error;
    }

    async getPromoCodes(active = true, page = 1) {
        const query = active ?
            'admin.listActivePromoCodes' :
            'admin.listInactivePromoCodes';

        const {
            error,
            data
        } = await this.request(query, {
            page
        });

        if(error)
            throw error;

        return data;
    }

    async createPromoCode({ users: maxUsers, name: promoCode, currency, reward }) {
        const {
            error
        } = await this.request('admin.createPromoCode', {
            promoCode: promoCode.toLowerCase(),
            reward: reward * 100,
            currency,
            maxUsers
        });

        if(error)
            throw error;
    }

    async cancelPromoCode(promoCode) {
        const {
            error
        } = await this.request('admin.cancelPromoCode', {
            promoCode
        });

        if(error)
            throw error;
    }

    async getPromoCodeUsers(promoCode, page) {
        const {
            error,
            data
        } = await this.request('admin.getPromoCodeUsers', {
            promoCode,
            page
        });

        if(error)
            throw error;

        return data;
    }

    async getConfig() {
        const {
            error,
            data
        } = await this.request('admin.getConfig');

        if(error)
            throw error;

        return data;
    }

    async setConfigGeneral(parameters) {
        const {
            error
        } = await this.request('admin.setConfigGeneral', parameters);

        if(error)
            throw error;
    }

    async setConfigTransactions(parameters) {
        const {
            error
        } = await this.request('admin.setConfigTransactions', parameters);

        if(error)
            throw error;
    }

    async setConfigCaptchas(parameters) {
        const {
            error
        } = await this.request('admin.setConfigCaptchas', parameters);

        if(error)
            throw error;
    }

    async setConfigAuthentication(parameters) {
        const {
            error
        } = await this.request('admin.setConfigAuthentication', parameters);

        if(error)
            throw error;
    }

    async setConfigAffiliates(parameters) {
        const {
            error
        } = await this.request('admin.setConfigAffiliates', parameters);

        if(error)
            throw error;
    }

    async setConfigRewards(parameters) {
        const {
            error
        } = await this.request('admin.setConfigRewards', parameters);

        if(error)
            throw error;
    }

    async setConfigRequirements(parameters) {
        const {
            error
        } = await this.request('admin.setConfigRequirements', parameters);

        if(error)
            throw error;
    }

    async setConfigSocial(parameters) {
        const {
            error
        } = await this.request('admin.setConfigSocial', parameters);

        if(error)
            throw error;
    }

    async updateItemList() {
        const {
            error
        } = await this.request('admin.updateItemList');

        if(error)
            throw error;
    }

    async getUser(userID) {
        const {
            error,
            data
        } = await this.request('admin.getUser', { subjectUUID: userID });

        if(error)
            throw error;

        return data.subject;
    }

    async setUserFlairs(userID, flairs) {
        const {
            error
        } = await this.request('admin.setUserFlairs', {
            subjectUUID: userID,
            flairs
        });

        if(error)
            throw error;
    }

    async setUserPermissions(userID, role, permissions) {
        const {
            error
        } = await this.request('admin.setUserPermissions', {
            subject: userID,
            role,
            permissions
        });

        if(error)
            throw error;
    }

    async setUserLimits(userID, limits) {
        const {
            error
        } = await this.request('admin.setUserLimits', {
            subjectUUID: userID,
            ...limits
        });

        if(error)
            throw error;
    }

    async creditUser(userID, amount, currency) {
        const {
            error
        } = await this.request('admin.creditUser', {
            subject: userID,
            currency,
            amount
        });

        if(error)
            throw error;
    }

    async banUser(userID, reason, expiration) {
        const {
            error
        } = await this.request('admin.banUser', {
            subject: userID,
            reason,
            expiration
        });

        if(error)
            throw error;
    }

    async unbanUser(userID) {
        const {
            error
        } = await this.request('admin.unbanUser', {
            subjectUUID: userID
        });

        if(error)
            throw error;
    }

    async getWagerStatistics(period = false, game = false) {
        const {
            error,
            data
        } = await this.request('admin.getWagerStatistics', {
            period,
            game
        });

        if(error)
            throw error;

        return data;
    }

    async getGameHistory(game, page) {
        const {
            error,
            data
        } = await this.request('admin.getGameHistory', {
            game,
            page
        });

        if(error)
            throw error;

        return data;
    }

    async getCases() {
        const {
            error,
            data = {}
        } = await this.get('app/cases');

        if(error)
            throw error;

        return data;
    }

    async createCase(caseObj) {
        const {
            error,
            data
        } = await this.request('admin.createCase', caseObj);

        if(error)
            throw error;

        return data;
    }

    async getCurrentItemList() {
        const lastUpdated = localStorage.getItem('items:lastUpdated') || false;

        if(lastUpdated && (Date.now() - 30_000) >= lastUpdated) {
            return {
                csgo: JSON.parse(localStorage.getItem('items:csgo.list') || '[]'),
                rust: JSON.parse(localStorage.getItem('items:rust.list') || '[]')
            };
        }

        const hashes = {
            csgo: localStorage.getItem('items:csgo.hash') || false,
            rust: localStorage.getItem('items:rust.hash') || false
        };

        if(hashes.csgo || hashes.rust) {
            const {
                csgo = false,
                rust = false
            } = await fetch('https://i.ape.gg/itemshash').then(res => {
                return res.json();
            });

            if(csgo === hashes.csgo && rust === hashes.rust) {
                return {
                    csgo: JSON.parse(localStorage.getItem('items:csgo.list') || '[]'),
                    rust: JSON.parse(localStorage.getItem('items:rust.list') || '[]')
                };
            }
        }

        const games = await fetch('https://i.ape.gg/items').then(res => {
            return res.json();
        });

        for(const game in games) {
            const { hash, items: _items } = games[ game ];

            if(!(hash.length)) {
                localStorage.removeItem(`items:${ game }.hash`);
                localStorage.removeItem(`items:${ game }.list`);

                continue;
            }

            switch (game) {
                case 'csgo': {
                    const items = _items.map(([ itemID, wear, rarity, name, price ]) => ({
                        price: +price,
                        itemID,
                        rarity,
                        ...parseName(name)
                    }));

                    localStorage.setItem(`items:${ game }.hash`, hash);
                    localStorage.setItem(`items:${ game }.list`, JSON.stringify(items));

                    logger.info(`Updated ${ game } items: ${ hash }`);
                    continue;
                }

                default: continue;
            }
        }

        return {
            csgo: JSON.parse(localStorage.getItem('items:csgo.list') || '[]'),
            rust: JSON.parse(localStorage.getItem('items:rust.list') || '[]')
        };
    }
}

export default new API();

// class SharedBackend {
//     socket = false;
//     isConnected = false;
//     token = false;

//     constructor(url) {
//         this.url = url;
//     }

//     async connect() {
//         if(this.isConnected)
//             return;

//         return new Promise((resolve, reject) => {
//             const { token } = this;

//             if(this.socket)
//                 this.socket.disconnect(true);

//             const socket = new window.WebSocketClient(`${ this.url }/socket`, token, {
//                 __deprecatedEventStructure: true,
//                 timeoutInterval: 2000,
//                 reconnect: false
//             });

//             socket.on('open', async () => {
//                 if(token) {
//                     return Promise.all([
//                         new Promise(localResolve => {
//                             socket.on('auth', isAuthed => {
//                                 if(!isAuthed)
//                                     reject('TOKEN_EXPIRED');
//                                 else localResolve();
//                             });
//                         }),
//                         new Promise(localResolve => {
//                             socket.on('app.user', user => {
//                                 localResolve(user);
//                             });
//                         }),
//                         this.subscribe('admin')
//                     ]).then(([ _, user ]) => {
//                         this.isConnected = true;
//                         resolve(user);
//                     }).catch(reject);
//                 }

//                 socket.send('subscribe', 'auth', () => {
//                     this.isConnected = true;
//                     resolve(false);
//                 });
//             });

//             socket.on('error', error => {
//                 logger.warn('Received error from socket');
//                 logger.error(error);

//                 this.isConnected = false;
//                 socket.removeAllListeners();
//             });

//             socket.on('close', event => {
//                 logger.warn('Disconnected from socket');
//                 logger.error(event.reason || event.code);

//                 if(!this.isConnected)
//                     return reject(event.reason || event.code);

//                 this.isConnected = false;
//                 this.socket = false;

//                 socket.removeAllListeners();
//             });

//             this.socket = socket;
//         });
//     }

//     subscribe(namespace) {
//         return new Promise((resolve, reject) => {
//             this.socket.send('subscribe', namespace, ({ error, data }) => {
//                 if(error)
//                     reject(error);
//                 else resolve();
//             });
//         });
//     }

//     async request(event, data = {}) {
//         try {
//             await this.connect();
//         } catch(error) {
//             return { error };
//         }

//         return new Promise(resolve => {
//             this.socket.send(event, data, ({ error, data }) => {
//                 if(error)
//                     return resolve({ error });

//                 resolve({ data });
//             });
//         });
//     }

//     async login(loginData) {
//         this.token = false;

//         const {
//             error,
//             data
//         } = await this.request('auth.login', loginData);

//         if(error)
//             return { error };

//         console.log(this.socket);

//         this.socket.disconnect();
//         this.token = data.token;

//         return this.connect()
//             .then(async user => {
//                 const {
//                     data: { permissions },
//                     error: permissionsError
//                 } = await this.request('admin.getCurrentPermissions');

//                 const {
//                     data: features,
//                     error: featuresError
//                 } = await this.request('admin.getFeatures');

//                 if(permissionsError || featuresError)
//                     throw (permissionsError || featuresError);

//                 return {
//                     auth: {
//                         user: {
//                             ...user,
//                             permissions
//                         },
//                         token: data.token
//                     },
//                     features
//                 };
//             })
//             .catch(error => ({
//                 error
//             }));
//     }

//     async fetchCurrentUser(token) {
//         this.token = token;
//         let user = false;

//         try {
//             user = await this.connect();
//         } catch(error) {
//             return { error };
//         }

//         if(!user)
//             return { error: 'INVALID_TOKEN' };

//         const {
//             data: { permissions },
//             error: permissionsError
//         } = await this.request('admin.getCurrentPermissions');

//         const {
//             data: features,
//             error: featuresError
//         } = await this.request('admin.getFeatures');

//         if(permissionsError || featuresError)
//             throw (permissionsError || featuresError);

//         return {
//             auth: {
//                 user: {
//                     ...user,
//                     permissions
//                 },
//                 token
//             },
//             features
//         };
//     }

//     async fetchPermissionList() {
//         const {
//             error,
//             data
//         } = await this.request('admin.listPermissions');

//         if(error)
//             return { error };

//         return data;
//     }

//     async fetchRoles() {
//         const {
//             error,
//             data
//         } = await this.request('admin.listRoles');

//         if(error)
//             return { error };

//         return data;
//     }

//     async updateRole(roleName, inherits, permissions) {
//         const {
//             error
//         } = await this.request('admin.editRole', {
//             role: roleName,
//             inherits,
//             permissions
//         });

//         return error || false;
//     }

//     async createRole(roleName, inherits, permissions) {
//         const {
//             error
//         } = await this.request('admin.createRole', {
//             role: roleName,
//             inherits,
//             permissions
//         });

//         return error || false;
//     }

//     async deleteRole(roleName) {
//         const {
//             error
//         } = await this.request('admin.deleteRole', {
//             role: roleName
//         });

//         return error || false;
//     }

//     async fetchFlairs() {
//         const {
//             error,
//             data
//         } = await this.request('admin.listFlairs');

//         if(error)
//             return { error };

//         return data;
//     }

//     async createFlair(flair) {
//         const {
//             error
//         } = await this.request('admin.createFlair', {
//             flair
//         });

//         return error || false;
//     }

//     async deleteFlair(flair) {
//         const {
//             error
//         } = await this.request('admin.deleteFlair', {
//             flair
//         });

//         return error || false;
//     }

//     async setUserFlair(userID, flair) {
//         const {
//             error
//         } = await this.request('admin.setUserFlair', {
//             subjectUUID: userID,
//             flair
//         });

//         return error || false;
//     }

//     async removeUserFlair(userID, flair) {
//         const {
//             error
//         } = await this.request('admin.removeUserFlair', {
//             subjectUUID: userID,
//             flair
//         });

//         return error || false;
//     }

//     async getAdminLogs(lastID) {
//         const {
//             error,
//             data
//         } = await this.request('admin.listLogHistory', {
//             last: lastID || undefined
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getConfig() {
//         const {
//             error,
//             data
//         } = await this.request('admin.getConfig');

//         if(error)
//             return { error };

//         return data;
//     }

//     async getGames() {
//         const {
//             error,
//             data
//         } = await this.request('admin.getGameList');

//         if(error)
//             return { error };

//         return data.games;
//     }

//     async setStaffOnly(staffOnly) {
//         const {
//             error
//         } = await this.request('admin.setStaffOnly', {
//             staffOnly
//         });

//         return error || false;
//     }

//     async setWaxpeerFee(waxpeerFee) {
//         const {
//             error
//         } = await this.request('admin.setWaxpeerFee', {
//             waxpeerFee
//         });

//         return error || false;
//     }

//     async setAffiliateRate(affiliate, rate) {
//         const {
//             error
//         } = await this.request('admin.setAffiliateRate', {
//             affiliate,
//             rate
//         });

//         return error || false;
//     }

//     async setAffiliateReward(affiliate, reward) {
//         const {
//             error
//         } = await this.request('admin.setAffiliateReward', {
//             affiliate,
//             reward
//         });

//         return error || false;
//     }

//     async setRewardConfig(config) {
//         const {
//             error
//         } = await this.request('admin.setRewardConfig', config);

//         return error || false;
//     }

//     async setAffiliateCurrency(affiliate, currency) {
//         const {
//             error
//         } = await this.request('admin.setAffiliateCurrency', {
//             affiliate,
//             currency
//         });

//         return error || false;
//     }

//     async setAffiliateOwner(affiliate, subject) {
//         const {
//             error
//         } = await this.request('admin.transferAffiliate', {
//             affiliate,
//             subject
//         });

//         return error || false;
//     }

//     async setHouseEdge(game, houseEdge) {
//         const {
//             error
//         } = await this.request('admin.setHouseEdge', {
//             game,
//             houseEdge
//         });

//         return error || false;
//     }

//     async setGameEnabled(game, enabled) {
//         const {
//             error
//         } = await this.request('admin.setGameState', {
//             game,
//             enabled
//         });

//         return error || false;
//     }

//     async setAnalyticsEnabled(analyticsEnabled) {
//         const {
//             error
//         } = await this.request('admin.setAnalyticsEnabled', {
//             analyticsEnabled
//         });

//         return error || false;
//     }

//     async setDiceItemsEnabled(itemsEnabled) {
//         const {
//             error
//         } = await this.request('admin.setDiceItemsEnabled', {
//             itemsEnabled
//         });

//         return error || false;
//     }

//     async getUsers(last = false) {
//         const {
//             error,
//             data
//         } = await this.request('admin.listUsers', {
//             last
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getUser(query) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getUser', query);

//         if(error)
//             return { error };

//         return data;
//     }

//     async setUserRole(userID, role) {
//         const {
//             error
//         } = await this.request('admin.setUserRole', {
//             subject: userID,
//             role
//         });

//         return error || false;
//     }

//     async unbanUser(userID) {
//         const {
//             error
//         } = await this.request('admin.unbanUser', {
//             subjectUUID: userID
//         });

//         return error || false;
//     }

//     async setUserPermissions(userID, permissions) {
//         const {
//             error
//         } = await this.request('admin.setUserPermissions', {
//             subject: userID,
//             permissions
//         });

//         return error || false;
//     }

//     async creditUser(userID, amount, currency) {
//         const {
//             error
//         } = await this.request('admin.creditUser', {
//             subject: userID,
//             currency,
//             amount
//         });

//         return error || false;
//     }

//     async banUser(userID, reason, expiration) {
//         const {
//             error
//         } = await this.request('admin.banUser', {
//             subject: userID,
//             reason,
//             expiration
//         });

//         return error || false;
//     }

//     async getAffiliates(last = false) {
//         const {
//             error,
//             data
//         } = await this.request('admin.listAffiliates', {
//             orderByCount: true,
//             last
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getTransactions(last = false, userID = false) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getTransactions', {
//             subjectUUID: userID,
//             last
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getUserActions(userID, last = false) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getUserActions', {
//             subjectUUID: userID,
//             last
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async checkPeerTransactionState(transactionID) {
//         const {
//             error,
//             data
//         } = await this.request('admin.checkPeerTransactionState', {
//             transactionID
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async refundPeerTransaction(transactionID) {
//         const {
//             error
//         } = await this.request('admin.refundPeerTransaction', {
//             transactionID
//         });

//         return error || false;
//     }

//     async clearPendingBalance(userID) {
//         const {
//             error
//         } = await this.request('admin.clearPendingBalance', {
//             subjectUUID: userID
//         });

//         return error || false;
//     }

//     async blacklistFromPeer(userID) {
//         const {
//             error
//         } = await this.request('admin.blacklistPeerUser', {
//             subjectUUID: userID
//         });

//         return error || false;
//     }

//     async generateGiftCards() {
//         const {
//             error,
//             data
//         } = await this.request('admin.generateGiftCards');

//         if(error)
//             return { error };

//         return data;
//     }

//     async getAffiliate(affiliate) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getAffiliate', {
//             affiliate
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getGameStatistics(game) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getGameStatistics', {
//             game
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getGameStatisticsForRange(game, from, to) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getGameStatisticsForRange', {
//             game,
//             from,
//             to
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getSiteStatistics() {
//         const {
//             error,
//             data
//         } = await this.request('admin.getSiteStatistics');

//         if(error)
//             return { error };

//         return data;
//     }

//     async getSiteStatisticsForRange(from, to) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getSiteStatisticsForRange', {
//             from,
//             to
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getTransferStatistics() {
//         const {
//             error,
//             data
//         } = await this.request('admin.getTransferStatistics');

//         if(error)
//             return { error };

//         return data;
//     }

//     async getTransferStatisticsForRange(from, to) {
//         const {
//             error,
//             data
//         } = await this.request('admin.getTransferStatisticsForRange', {
//             from,
//             to
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async setRouletteTiming(timing) {
//         const {
//             error
//         } = await this.request('admin.setRouletteTiming', timing);

//         return error || false;
//     }

//     async setRoulettePayout(colour, value) {
//         const {
//             error
//         } = await this.request('admin.setRoulettePayout', {
//             colour,
//             value
//         });

//         return error || false;
//     }

//     async setRouletteColourCount(colourCount) {
//         const {
//             error
//         } = await this.request('admin.setRouletteColourCount', {
//             colourCount
//         });

//         return error || false;
//     }

//     async setRouletteBonusEnabled(isBonusEnabled) {
//         const {
//             error
//         } = await this.request('admin.setRouletteBonusEnabled', {
//             isBonusEnabled
//         });

//         return error || false;
//     }

//     async setCrashTiming(timing) {
//         const {
//             error
//         } = await this.request('admin.setCrashTiming', timing);

//         return error || false;
//     }

//     async setCrashSpeed(speed) {
//         const {
//             error
//         } = await this.request('admin.setCrashSpeed', {
//             modifier: speed
//         });

//         return error || false;
//     }

//     async setCrashNumeric(options) {
//         const {
//             error
//         } = await this.request('admin.setCrashNumeric', options);

//         return error || false;
//     }

//     async setDiceNumeric(options) {
//         const {
//             error
//         } = await this.request('admin.setDiceNumeric', options);

//         return error || false;
//     }

//     async setRouletteNumeric(options) {
//         const {
//             error
//         } = await this.request('admin.setRouletteNumeric', options);

//         return error || false;
//     }

//     async setCoinflipNumeric(options) {
//         const {
//             error
//         } = await this.request('admin.setCoinflipNumeric', options);

//         return error || false;
//     }

//     async setCoinflipTiming(options) {
//         const {
//             error
//         } = await this.request('admin.setCoinflipTiming', options);

//         return error || false;
//     }

//     async setCoinflipHouseEdge(options) {
//         const {
//             error
//         } = await this.request('admin.setCoinflipHouseEdge', options);

//         return error || false;
//     }

//     async setCrashBlockHash(blockHash) {
//         const {
//             error
//         } = await this.request('admin.setCrashBlockHash', {
//             blockHash
//         });

//         return error || false;
//     }

//     async setTransactionState(key, state) {
//         const {
//             error
//         } = await this.request('admin.setTransactionState', {
//             key,
//             state
//         });

//         return error || false;
//     }

//     async setAuthState(key, state) {
//         const {
//             error
//         } = await this.request('admin.setAuthState', {
//             key,
//             state
//         });

//         return error || false;
//     }

//     async setCaptchaState(key, state) {
//         const {
//             error
//         } = await this.request('admin.setCaptchaState', {
//             key,
//             state
//         });

//         return error || false;
//     }

//     async setWithdrawRequirement(userID, withdrawRequirement) {
//         const {
//             error
//         } = await this.request('admin.setWithdrawRequirement', {
//             subjectUUID: userID,
//             withdrawRequirement
//         });

//         return error || false;
//     }

//     async setChatRequirement(chatRequirement) {
//         const {
//             error
//         } = await this.request('admin.setChatRequirement', {
//             chatRequirement
//         });

//         return error || false;
//     }

//     async setDailyWithdrawLimit(userID, dailyWithdrawLimit) {
//         const {
//             error
//         } = await this.request('admin.setDailyWithdrawLimit', {
//             subjectUUID: userID,
//             dailyWithdrawLimit
//         });

//         return error || false;
//     }

//     async setWithdrawLocked(userID, isWithdrawLocked) {
//         const {
//             error
//         } = await this.request('admin.setWithdrawLock', {
//             subjectUUID: userID,
//             isWithdrawLocked
//         });

//         return error || false;
//     }

//     async setPeerWithdrawFilter(peerWithdrawFilter) {
//         const {
//             error
//         } = await this.request('admin.setPeerWithdrawFilter', {
//             peerWithdrawFilter
//         });

//         return error || false;
//     }

//     async updateItemList() {
//         const {
//             error
//         } = await this.request('admin.updateItemList');

//         return error || false;
//     }

//     async setNotice(notice) {
//         const {
//             error
//         } = await this.request('admin.setNotice', { notice });

//         return error || false;
//     }

//     async setSocialMedia(socialMedia) {
//         const {
//             error
//         } = await this.request('admin.setSocialMedia', { socialMedia });

//         return error || false;
//     }

//     async getInactivePromoCodes(last) {
//         const {
//             error,
//             data
//         } = await this.request('admin.listInactivePromoCodes', {
//             last: last || undefined
//         });

//         if(error)
//             return { error };

//         return data;
//     }

//     async getActivePromoCodes() {
//         const {
//             error,
//             data
//         } = await this.request('admin.listActivePromoCodes');

//         if(error)
//             return { error };

//         return data;
//     }

//     async createPromoCode(promoCode) {
//         const {
//             error
//         } = await this.request('admin.createPromoCode', promoCode);

//         return error || false;
//     }

//     async cancelPromoCode(promoCode) {
//         const {
//             error
//         } = await this.request('admin.cancelPromoCode', {
//             promoCode
//         });

//         return error || false;
//     }
// }