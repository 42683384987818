import api from 'api';
import React from 'react';

import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { IconAlertTriangle } from '@tabler/icons';

const Unban = ({ loading, setLoading, setError, query, setQuery, user, getUser, currencies }) => {
    const onCloseModal = useCallback(() => {
        query.delete('unban');
        setQuery(query);
    }, [ query ]);

    const onUnbanUser = useCallback(() => {
        setLoading(true);

        return api
            .unbanUser(
                user.userID
            )
            .then(() => {
                return getUser();
            })
            .then(() => {
                onCloseModal();
            })
            .catch(error => {
                console.warn('Failed to unban user');
                console.error(error);
                setError(error);
            });
    }, [ user ]);

    return (
        <Modal
            dialogClassName='modal-sm'
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ true }
            centered
        >
            <div className='modal-content'>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
                <div className='modal-status bg-danger' />
                <div className='modal-body text-center py-4 pb-3'>
                    <IconAlertTriangle className='icon mb-2 text-danger icon-lg' />
                    <h3>
                        Unban { user.username }
                    </h3>
                    <div className='text-muted'>
                        Are you sure you want to unban { user.username }? They were banned for <code>{ user[ 'ban:reason' ] }</code>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <button className='btn btn-danger w-100' disabled={ loading } onClick={ onUnbanUser}>
                                    Unban
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default Unban;