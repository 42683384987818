import Statistics from './Statistics';
import Transactions from './Transactions';
import Auth from './Auth';
import Error from './Error';
import SystemLogs from './SystemLogs';
import Users from './Users';
import Roles from './Users/Roles';
import Flairs from './Users/Flairs';
import PromoCodes from './PromoCodes';
import SiteConfiguration from './SiteConfiguration';
import Crash from './Games/Crash';
import Coinflip from './Games/Coinflip';
import Roulette from './Games/Roulette';
import Battles from './Games/Battles';
import Double from './Games/Double';
import Plinko from './Games/Plinko';
import Dice from './Games/Dice';
import Hub from './Games/Hub';
import Cases from './Games/Cases';
import CaseCreator from './CaseCreator';

import { Permissions } from 'utils/permissions';

export default {
    auth: [ Auth ],
    error: [ Error ],

    'dashboard/statistics': [ Statistics, Permissions.CAN_VIEW_STATISTICS ],
    'dashboard/transactions': [ Transactions, Permissions.CAN_VIEW_TRANSACTIONS ],

    'games/coinflip': [ Coinflip, Permissions.CAN_VIEW_STATISTICS ],
    'games/roulette': [ Roulette, Permissions.CAN_VIEW_STATISTICS ],
    'games/battles': [ Battles, Permissions.CAN_VIEW_STATISTICS ],
    'games/double': [ Double, Permissions.CAN_VIEW_STATISTICS ],
    'games/plinko': [ Plinko, Permissions.CAN_VIEW_STATISTICS ],
    'games/cases': [ Cases, Permissions.CAN_VIEW_STATISTICS ],
    'games/crash': [ Crash, Permissions.CAN_VIEW_STATISTICS ],
    'games/dice': [ Dice, Permissions.CAN_VIEW_STATISTICS ],
    'games/hub': [ Hub, Permissions.CAN_VIEW_STATISTICS ],

    users: [ Users, Permissions.CAN_VIEW_USER ],
    'users/roles': [ Roles, Permissions.CAN_MODIFY_ROLE ],
    'users/flairs': [ Flairs, Permissions.CAN_MODIFY_FLAIR ],

    'case-creator': [ CaseCreator, Permissions.CREATE_CASE ],
    affiliates: [ 'Not implemented', Permissions.CAN_VIEW_AFFILIATE ],
    'promo-codes': [ PromoCodes, Permissions.CAN_VIEW_PROMO_CODE ],
    settings: [ SiteConfiguration, Permissions.CAN_MODIFY_CONFIG ],
    logs: [ SystemLogs, Permissions.CAN_VIEW_ADMIN_LOGS ],
};