import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

const Rewards = ({ setLoading, getConfig, loading, config, show, siteConfig }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('freeCoins.enabled', { defaultValue: config.freeCoins?.enabled });
        resetField('freeCoins.amount', { defaultValue: config.freeCoins?.amount / 100 });
        resetField('faucet.duration', { defaultValue: config.faucet?.duration / 1000 });
        resetField('faucet.enabled', { defaultValue: config.faucet?.enabled });
        resetField('faucet.amount', { defaultValue: config.faucet?.amount / 100 });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        if(updatedConfig.freeCoins.enabled !== config.freeCoins.enabled)
            parameters.freeCoinsEnabled = updatedConfig.freeCoins.enabled;

        if(updatedConfig.faucet.enabled !== config.faucet.enabled)
            parameters.faucetEnabled = updatedConfig.faucet.enabled;

        if((updatedConfig.freeCoins.amount * 100) !== config.freeCoins.amount)
            parameters.freeCoinsAmount = updatedConfig.freeCoins.amount * 100;

        if((updatedConfig.faucet.duration * 100) !== config.faucet.duration)
            parameters.faucetDuration = updatedConfig.faucet.duration * 100;

        if((updatedConfig.faucet.amount * 100) !== config.faucet.amount)
            parameters.faucetAmount = updatedConfig.faucet.amount * 100;

        setLoading(true);

        api
            .setConfigRewards(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    return (
        <div className={ !(show) ? 'd-none' : '' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Rewards
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Faucet enabled
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('faucet.enabled') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Faucet reward
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                $
                            </span>
                            <input
                                placeholder='0.3'
                                className={ `form-control ps-0 ${ formState.errors.faucet?.amount ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('faucet.amount', {
                                    required: true,
                                    pattern: /^\d{0,2}(\.\d{1,2})?$/,
                                    min: 0.01,
                                    max: 10
                                }) }
                            />
                        </div>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.faucet?.amount?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Amount users are paid out when redeeming the faucet
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Faucet timeout
                    </label>
                    <div className='col'>
                        <input
                            placeholder='0.3'
                            className={ `form-control ${ formState.errors.faucet?.duration ? 'is-invalid' : '' }` }
                            disabled={ loading }
                            type='text'
                            { ...register('faucet.duration', {
                                required: true,
                                pattern: /^\d+$/,
                                min: 1,
                                max: 604800
                            }) }
                        />
                        { formState.errors?.notice?.duration ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.faucet?.duration }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Interval users are required to wait between claiming faucet in seconds
                            </small>
                        ) }
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Free coins enabled
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('freeCoins.enabled') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Free coins reward
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                $
                            </span>
                            <input
                                placeholder='0.3'
                                className={ `form-control ps-0 ${ formState.errors.freeCoins?.amount ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('freeCoins.amount', {
                                    required: true,
                                    pattern: /^\d{0,2}(\.\d{1,2})?$/,
                                    min: 0.01,
                                    max: 10
                                }) }
                            />
                        </div>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.freeCoins?.amount?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Amount users are paid out when claiming free coins
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(formState.isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Rewards;