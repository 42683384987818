import logger from 'redux-logger';

import {
    configureStore,
    getDefaultMiddleware
} from '@reduxjs/toolkit';

import app from './reducers/app';
import * as appActions from './actions/app';

const store = configureStore({
    reducer: {
        app
    },
    middleware: [
        ...getDefaultMiddleware(),
        logger
    ],
    devTools: process.env.NODE_ENV === 'development' && {
        actionCreators: {
            ...appActions
        }
    }
});

export default store;