import React from 'react';
import api from 'api';
import Pagination from 'components/ui/Pagination';
import formatDate from 'dateformat';

import { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import './SystemLogs.scss';

const DEFAULT_PAGINATION = {
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    total: 0,
    from: 0,
    to: 0
};

const SystemLogs = ({ site }) => {
    const [ isLoading, setLoading ] = useState(true);
    const [ logs, setLogs ] = useState([]);

    const [ pagination, setPagination ] = useState(DEFAULT_PAGINATION);

    const {
        handleSubmit,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const username = watch('username');

    const setCurrentPage = useCallback(page => {
        setLoading(true);

        api
            .getSystemLogs(page, formState.isValid && username)
            .then(({ history, pagination }) => {
                setPagination(pagination);
                setLoading(false);
                setLogs(history);
            })
            .catch(error => {
                console.warn('Failed to fetch system logs');
                console.error(error);

                setPagination(DEFAULT_PAGINATION);
                setLoading(false);
                setLogs([]);
            });
    }, [ formState.isValid, username ]);

    useEffect(() => {
        setCurrentPage(1);
    }, [ site ]);

    const onSubmit = async () => {
        setCurrentPage(1);
    };

    return (
        <div className='page-body system-logs'>
            <div className='container-xl'>
                <div className='row row-deck row-cards'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>
                                    System logs
                                </h3>
                                <div className='card-actions'>
                                    <form className='input-group' onSubmit={ handleSubmit(onSubmit) }>
                                        <input
                                            type='text'
                                            className='form-control'
                                            placeholder='Search by username'
                                            disabled={ isLoading }
                                            { ...register('username', {
                                                pattern: /^[a-zA-Z-._0-9]{3,20}$/
                                            }) }
                                        />
                                        <input
                                            className='btn btn-primary'
                                            disabled={ isLoading || !formState.isValid }
                                            value='Search'
                                            type='submit'
                                        />
                                    </form>
                                </div>
                            </div>
                            <div className='card-table table-responsive'>
                                <table className='table table-vcenter'>
                                    <thead>
                                        <tr>
                                            <th>User</th>
                                            <th>Action</th>
                                            <th>Related information</th>
                                            <th colSpan={ 2 }>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody className='placeholder-glow'>
                                        { (isLoading && !(logs.length)) ? Array.from({ length: 10 }).map((_, index) => (
                                            <tr key={ index }>
                                                <td className='user' style={{ minWidth: '5em' }}>
                                                    <span className='avatar avatar-sm me-3 placeholder' />
                                                </td>
                                                <td style={{ minWidth: '10em' }}>
                                                    <span className='placeholder placeholder-xs col-12' />
                                                </td>
                                                <td className='td-truncate'>
                                                    <div className='text-truncate'>
                                                        <code className='placeholder placeholder-xs col-9' />
                                                    </div>
                                                </td>
                                                <td className='text-nowrap text-muted' style={{ minWidth: '5em' }}>
                                                    <span className='placeholder placeholder-xs col-12' />
                                                </td>
                                                <td className='text-nowrap text-muted' style={{ minWidth: '5em' }}>
                                                    <span className='placeholder placeholder-xs col-12' />
                                                </td>
                                            </tr>
                                        )) : logs.length ? logs.map(log => (
                                            <tr key={ log.actionID }>
                                                <td className='user'>
                                                    <Link to={ `/${ site }/users?user=${ log.userID }`}>
                                                        <span
                                                            className='avatar avatar-sm me-3'
                                                            style={{
                                                                backgroundImage: `url(${ log[ 'avatar:image' ] })`
                                                            }}
                                                        />
                                                        { log.username }
                                                    </Link>
                                                </td>
                                                <td>
                                                    { log.action }
                                                </td>
                                                <td className='td-truncate'>
                                                    <div className='text-truncate'>
                                                        <code>
                                                            { JSON.stringify(log.meta, null, 2) }
                                                        </code>
                                                    </div>
                                                </td>
                                                <td className='text-nowrap text-muted'>
                                                    { formatDate(log.occurredAt, 'ddd mmm dd') }
                                                </td>
                                                <td className='text-nowrap text-muted'>
                                                    { formatDate(log.occurredAt, 'h:MM:ss TT') }
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td className='text-center' colSpan={ 5 } style={{ padding: '2rem' }}>
                                                    No entries found
                                                </td>
                                            </tr>
                                        ) }
                                    </tbody>
                                </table>
                            </div>
                            <div className='card-footer d-flex align-items-center'>
                                <p className='m-0 text-muted'>
                                    Showing <strong>
                                        { pagination.to ? (pagination.from + 1) : 0 }
                                    </strong> to <strong>
                                        { pagination.to }
                                    </strong> of <strong>
                                        { pagination.total }
                                    </strong> entries
                                </p>
                                <Pagination
                                    onPageChange={ setCurrentPage }
                                    currentPage={ pagination.currentPage }
                                    totalCount={ pagination.total }
                                    pageSize={ pagination.perPage }
                                    disabled={ isLoading || ((username || '').length && !(formState.isValid)) }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SystemLogs;