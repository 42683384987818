import React from 'react';
import api from 'api';
import Pagination from 'components/ui/Pagination';
import formatDate from 'dateformat';
import User from './modals/User';

import { useEffect, useState, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { version as uuidVersion, validate as uuidValidate } from 'uuid';

import './Users.scss';

const ROLE_COLOURS = {
    administrator: 'text-red',
    moderator: 'text-yellow',
    helper: 'text-cyan',
    vip: 'text-purple'
};

const DEFAULT_PAGINATION = {
    currentPage: 1,
    total: 0
};

const getValueOfUser = user => {
    return Object
        .values(user.balance)
        .reduce((total, amount) => {
            return total + +amount;
        }, 0);
};

const Users = ({ site }) => {
    const [ pagination, setPagination ] = useState(DEFAULT_PAGINATION);
    const [ isLoading, setLoading ] = useState(true);
    const [ userID, setUserID ] = useState(false);
    const [ users, setUsers ] = useState([]);
    const [ query, setQuery ] = useSearchParams();

    const {
        handleSubmit,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const username = watch('username');

    const setCurrentPage = useCallback(page => {
        setLoading(true);

        api
            .getUserList(page, formState.isValid && username)
            .then(({ users, total }) => {
                setUsers(users.sort((a, b) => {
                    return getValueOfUser(b) - getValueOfUser(a);
                }));

                setPagination({
                    currentPage: page,
                    total
                });

                setLoading(false);
            })
            .catch(error => {
                console.warn('Failed to fetch users');
                console.error(error);

                setPagination(DEFAULT_PAGINATION);
                setLoading(false);
                setUsers([]);
            });
    }, [ formState.isValid, username ]);

    useEffect(() => {
        setCurrentPage(1);
    }, [ site ]);

    const onSubmit = async () => {
        setCurrentPage(1);
    };

    const onCloseModal = useCallback(() => {
        query.forEach((_, key) => {
            query.delete(key);
        });

        setQuery(query);
    }, []);

    useEffect(() => {
        const userID = query.get('user');

        if(uuidValidate(userID) && uuidVersion(userID) === 4)
            return setUserID(userID);

        if(!(userID))
            setUserID(false);

        query.forEach((_, key) => {
            query.delete(key);
        });

        setQuery(query);
    }, [ query ]);

    return (
        <>
            <div className='page-body users'>
                <div className='container-xl'>
                    <div className='row row-deck row-cards'>
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h3 className='card-title'>
                                        Site members
                                    </h3>
                                    <div className='card-actions'>
                                        <form className='input-group' onSubmit={ handleSubmit(onSubmit) }>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search by username'
                                                disabled={ isLoading }
                                                { ...register('username', {
                                                    pattern: /^[a-zA-Z-._0-9]{3,20}$/
                                                }) }
                                            />
                                            <input
                                                className='btn btn-primary'
                                                disabled={ isLoading || !formState.isValid }
                                                value='Search'
                                                type='submit'
                                            />
                                        </form>
                                    </div>
                                </div>
                                <div className='card-table table-responsive'>
                                    <table className='table table-vcenter'>
                                        <thead>
                                            <tr>
                                                <th>User</th>
                                                <th>Role</th>
                                                <th>Balance</th>
                                                <th>Registered</th>
                                            </tr>
                                        </thead>
                                        <tbody className='placeholder-glow'>
                                            { (isLoading && !(users.length)) ? Array.from({ length: 10 }).map((_, index) => (
                                                <tr key={ index }>
                                                    <td className='user'>
                                                        <div className='d-flex py-1 align-items-center' style={{ minWidth: '10em' }}>
                                                            <span className='avatar me-2 placeholder' />
                                                            <div className='flex-fill'>
                                                                <div className='font-weight-medium'>
                                                                    <span className='placeholder placeholder-xs col-10' />
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <span className='placeholder placeholder-xs col-7' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-nowrap text-muted' style={{ minWidth: '5em' }}>
                                                        <span className='placeholder placeholder-xs col-10' />
                                                    </td>
                                                    <td className='balance' style={{ minWidth: '20em' }}>
                                                        <div className='card me-2 bg-secondary-lt' style={{ width: '7em' }}>
                                                            <div className='card-body text-center'>
                                                                <div className='font-weight-medium'>
                                                                    <span className='placeholder placeholder-xs col-12' />
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <span className='placeholder placeholder-xs col-7' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='card me-2 bg-secondary-lt' style={{ width: '7em' }}>
                                                            <div className='card-body text-center'>
                                                                <div className='font-weight-medium'>
                                                                    <span className='placeholder placeholder-xs col-12' />
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <span className='placeholder placeholder-xs col-7' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='card me-2 bg-secondary-lt' style={{ width: '7em' }}>
                                                            <div className='card-body text-center'>
                                                                <div className='font-weight-medium'>
                                                                    <span className='placeholder placeholder-xs col-12' />
                                                                </div>
                                                                <div className='text-muted'>
                                                                    <span className='placeholder placeholder-xs col-7' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-nowrap text-muted' style={{ minWidth: '2em' }}>
                                                        <span className='placeholder placeholder-xs col-12' />
                                                    </td>
                                                </tr>
                                            )) : users.length ? users.map(user => (
                                                <tr key={ user.userID }>
                                                    <td className='user'>
                                                        <Link to={ `/${ site }/users?user=${ user.userID }`}>
                                                            <div className='d-flex py-1 align-items-center'>
                                                                <span className='avatar me-2' style={{
                                                                    backgroundImage: `url(${ user.avatar })`
                                                                }} />
                                                                <div className='flex-fill'>
                                                                    <div className='font-weight-medium'>
                                                                        { user.username }
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Level { user.xp.level }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td className='text-nowrap text-muted'>
                                                        { user.role || 'Member' }
                                                    </td>
                                                    <td className='balance'>
                                                        { Object.entries(user.balance).map(([ currency, amount ]) => (
                                                            <div className='card me-2 bg-secondary-lt' key={ currency }>
                                                                <div className='card-body text-center'>
                                                                    <div className='font-weight-medium'>
                                                                        ${ Number(amount / 100).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }) }
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        { currency }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) }
                                                    </td>
                                                    <td className='text-nowrap text-muted'>
                                                        { formatDate(user.createdAt, 'mmmm dd, yyyy') }
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td className='text-center' colSpan={ 5 } style={{ padding: '2rem' }}>
                                                        No entries found
                                                    </td>
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='card-footer d-flex align-items-center'>
                                    <p className='m-0 text-muted'>
                                        Showing <strong>
                                            { ((pagination.currentPage - 1) * 10) + 1 }
                                        </strong> to <strong>
                                            { ((pagination.currentPage - 1) * 10) + users.length }
                                        </strong> of <strong>
                                            { pagination.total }
                                        </strong> entries
                                    </p>
                                    <Pagination
                                        onPageChange={ setCurrentPage }
                                        currentPage={ pagination.currentPage }
                                        totalCount={ pagination.total }
                                        pageSize={ 10 }
                                        disabled={ isLoading }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { userID ? (
                <User
                    onCloseModal={ onCloseModal }
                    setQuery={ setQuery }
                    userID={ userID }
                    query={ query }
                />
            ) : '' }
        </>
    );
};

export default Users;
export { ROLE_COLOURS };