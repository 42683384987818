import React from 'react';

import { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

const CreatePromoCode = ({ loading, activePromoCodes, error, onCloseModal, onCreate, config, features }) => {
    const {
        handleSubmit,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const currency = watch('currency');
    const reward = watch('reward');
    const users = watch('users', '1');
    const name = watch('name');

    const onSubmit = async () => {
        onCreate({
            reward: +reward,
            users: +users,
            currency,
            name
        });
    };

    return (
        <Modal
            animation={ false }
            onHide={ onCloseModal }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Create promo code
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Name
                </label>
                <input
                    placeholder='Name'
                    className={ `form-control ${ name?.length && formState.errors.name ? 'is-invalid' : '' }` }
                    disabled={ loading }
                    type='text'
                    { ...register('name', {
                        validate: name => !(activePromoCodes.some(({ promoCode }) => promoCode === name.toLowerCase()) ),
                        required: true,
                        pattern: /^[a-zA-Z][a-zA-Z0-9]{3,11}$/
                    }) }
                />
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Reward
                </label>
                <div className='input-group'>
                    <input
                        placeholder='Reward amount'
                        className={ `form-control ${ reward?.length && formState.errors.reward ? 'is-invalid' : '' }` }
                        disabled={ loading }
                        style={{ flex: 3, paddingRight: '0.75em' }}
                        type='text'
                        { ...register('reward', {
                            required: true,
                            pattern: /^[1-9]\d{0,2}(\.\d{1,2})?$/
                        }) }
                    />
                    <select
                        className='form-select'
                        disabled={ loading }
                        defaultValue='cash'
                        { ...register('currency', {
                            value: 'cash'
                        }) }
                    >
                        { config.currencies.map(currency => (
                            <option value={ currency } key={ currency }>
                                { currency}
                            </option>
                        )) }
                    </select>
                </div>
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Maximum users
                    <span className='form-label-description'>
                        { users }
                    </span>
                </label>
                <input
                    className='form-range mb-2 text-red'
                    disabled={ loading }
                    type='range'
                    min={ 1 }
                    max={ 500 }
                    defaultValue={ 1 }
                    { ...register('users') }
                />
            </div>
            { error ? (
                <div className='modal-body message'>
                    <div className='card bg-red-lt text-center'>
                        <div className='card-body'>
                            <h3 className='card-title mb-0'>
                                Failed to create promo code
                            </h3>
                            <p className='text-muted'>
                                { error }
                            </p>
                        </div>
                    </div>
                </div>
            ) : '' }
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (!(formState.isValid) || loading || !(formState.isDirty)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Creating<span className='animated-dots' />
                        </span>
                    ) : 'Create promo code' }
                </button>
            </div>
        </Modal>
    );
};

export default connect(state => ({
    features: state.app.features,
    config: state.app.config
}))(memo(CreatePromoCode));