import api from 'api';
import React from 'react';

import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

const Credit = ({ loading, setLoading, setError, query, setQuery, user, getUser, currencies }) => {
    const {
        handleSubmit,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    const onCloseModal = useCallback(() => {
        query.delete('credit');
        setQuery(query);
    }, [ query ]);

    const onSubmit = (credit, { amount, currency }) => {
        setLoading(true);

        return api
            .creditUser(
                user.userID,
                amount * (credit ? 100 : -100),
                currency
            )
            .then(() => {
                return getUser();
            })
            .then(() => {
                onCloseModal();
            })
            .catch(error => {
                console.warn('Failed to credit user');
                console.error(error);
                setError(error);
            });
    };

    return (
        <Modal
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Credit { user.username }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Amount
                </label>
                <div className='input-group input-group-flat'>
                    <span className='input-group-text'>
                        $
                    </span>
                    <input
                        className={ `form-control ps-0 ${ formState.errors.amount ? 'is-invalid is-invalid-lite' : '' }` }
                        disabled={ loading }
                        type='text'
                        { ...register('amount', {
                            required: true,
                            pattern: /^\d{0,5}(\.\d{1,2})?$/,
                            value: 0.01,
                            min: 0.01,
                            max: 1500
                        }) }
                    />
                </div>
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Currency
                </label>
                <select
                    defaultValue='cash'
                    className='form-select'
                    disabled={ loading }
                    { ...register('currency', {
                        value: 'cash'
                    }) }
                >
                    { currencies.map(currency => (
                        <option value={ currency } key={ currency }>
                            { currency}
                        </option>
                    )) }
                </select>
            </div>
            <div className='modal-footer'>
                { !(loading) ? (
                    <button
                        className={ `btn btn-danger ${ (loading || !(formState.isDirty) || !(formState.isValid)) ? 'disabled' : '' }` }
                        onClick={ handleSubmit(onSubmit.bind(null, false)) }
                        type='submit'
                    >
                        { loading ? (
                            <span>
                                Discrediting<span className='animated-dots' />
                            </span>
                        ) : 'Discredit' }
                    </button>
                ) : '' }
                <button
                    className={ `btn btn-primary ${ (loading || !(formState.isDirty) || !(formState.isValid)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit.bind(null, true)) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Updating balance<span className='animated-dots' />
                        </span>
                    ) : 'Credit' }
                </button>
            </div>
        </Modal>
    );
};

export default connect(state => ({
    currencies: state.app.config.currencies
}))(Credit);