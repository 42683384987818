const sites = {
    'f7001c01-5b1a-405e-904d-f404383ef1dd': {
        name: 'Ape',
        api: 'https://api.ape.gg',
        env: 'production'
    },

    '6ecc6841-66d9-48fb-8b64-60059d5315fb': {
        name: 'Ape',
        api: 'https://staging.luck.gg',
        env: 'staging'
    },

    '89c7292d-45f5-45bb-975f-bfe2cc78c413': {
        name: 'Ape',
        api: 'http://127.0.0.1:3001',
        env: 'local'
    },

    // '6bb71f2e-0511-4a22-b831-09b5a95e9d5a': {
    //     name: 'Bustadrop',
    //     api: 'http://banditcamp-staging-1666332831.eu-central-1.elb.amazonaws.com',
    //     env: 'staging'
    // }
};

const isLocal = process.env.NODE_ENV === 'development';

for(const site in sites) {
    if(sites[ site ].env === 'local' && !(isLocal))
        delete sites[ site ];
}

export default sites;