import api from 'api';
import React from 'react';
import ApexCharts from 'apexcharts';

import { useCallback, useLayoutEffect, useState, memo } from 'react';
import { IconCurrencyDollar, IconPlayCard, IconWallet } from '@tabler/icons';
import { generateChartOptions, generateCurrencyFormatter } from 'utils/charts';

const PERIODS = {
    '1h': '1 hour',
    '6h': '6 hours',
    '1d': '24 hours',
    '7d': '7 days',
    '14d': '14 days',
    '30d': '30 days'
};

const DEFAULT_STATISTICS = {
    rewarded: 0,
    wagered: 0,
    profit: 0,
    wagers: 0,
    users: 0
};

const GameStatistics = ({ site, game }) => {
    const [ historical, setHistorical ] = useState([]);
    const [ statistics, setStatistics ] = useState(DEFAULT_STATISTICS);
    const [ loading, setLoading ] = useState(0);
    const [ period, setPeriod ] = useState('1h');
    const [ totals, setTotals ] = useState(DEFAULT_STATISTICS);

    const setHistoricalPeriod = useCallback((newPeriod, event) => {
        if(event)
            (new window.bootstrap.Dropdown(document.getElementById('dropdown-game-list'))).hide();

        if(event && newPeriod === period)
            return;

        setLoading(loading => loading + 1);
        setPeriod(newPeriod);

        api.getWagerStatistics(newPeriod, game).then(data => {
            const totals = data.reduce((totals, row) => {
                totals.rewarded += +row.rewarded;
                totals.wagered += +row.wagered;
                totals.profit += +row.profit;
                totals.wagers += +row.wagers;
                totals.users = Math.max(totals.users, row.users ?? 0);

                return totals;
            }, { ...DEFAULT_STATISTICS });

            setHistorical(data.sort((a, b) => a.time - b.time));
            setLoading(loading => loading - 1);
            setTotals(totals);
        }).catch(error => {
            console.warn('Failed to fetch historical statistics');
            console.error(error);
            setLoading(loading => loading - 1);
        });
    }, [ period, game ]);

    const getStatistics = () => {
        setStatistics(DEFAULT_STATISTICS);
        setLoading(loading => loading + 1);

        return api
            .getWagerStatistics(false, game)
            .then(([ statistics = DEFAULT_STATISTICS ]) => {
                setStatistics(statistics);
                setLoading(loading => loading - 1);
            })
            .catch(error => {
                console.warn('Failed to fetch statistics');
                console.error(error);
                setLoading(loading => loading - 1);
            });
    };

    useLayoutEffect(() => {
        getStatistics().finally(() => {
            setHistoricalPeriod('1h');
        });
    }, [ site, game ]);

    useLayoutEffect(() => {
        if(!(historical.length))
            return;

        const labels = [];
        const series = {
            rewarded: { name: 'Amount rewarded', data: [] },
            wagered: { name: 'Amount wagered', data: [] }
        };

        historical.forEach(row => {
            labels.push(row.time);
            series.wagered.data.push(row.rewarded / 100);
            series.rewarded.data.push(row.wagered / 100);
        });

        if(window.chart) {
            window.chart.updateOptions({
                series: Object.values(series),
                labels
            });
        } else {
            window.chart = new ApexCharts(document.getElementById('chart-game-historical'), {
                ...generateChartOptions(),
                ...generateCurrencyFormatter(),
                colors: [ '#ae3ec9', '#206bc4' ],
                series: Object.values(series),
                labels
            });

            window.chart.render();
        }
    }, [ historical ]);

    return (
        <>
            <div className='col-12'>
                <div className='row row-cards'>
                    <div className='col-sm-6 col-md-4'>
                        <div className='card card-sm'>
                            <div className='card-body'>
                                <div className='row align-items-center'>
                                    <div className='col-auto'>
                                        <span className='bg-blue-lt text-white avatar'>
                                            <IconCurrencyDollar />
                                        </span>
                                    </div>
                                    <div className='col'>
                                        <div className='font-weight-medium'>
                                            ${ Number(statistics.wagered / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) } wagered
                                        </div>
                                        <div className='text-muted'>
                                            Amount users have wagered
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='card card-sm'>
                            <div className='card-body'>
                                <div className='row align-items-center'>
                                    <div className='col-auto'>
                                        <span className='bg-green-lt text-white avatar'>
                                            <IconWallet />
                                        </span>
                                    </div>
                                    <div className='col'>
                                        <div className='font-weight-medium'>
                                            ${ Number(statistics.profit / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) } profit
                                        </div>
                                        <div className='text-muted'>
                                            Amount of profit for us
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-4'>
                        <div className='card card-sm'>
                            <div className='card-body'>
                                <div className='row align-items-center'>
                                    <div className='col-auto'>
                                        <span className='bg-orange-lt text-white avatar'>
                                            <IconPlayCard />
                                        </span>
                                    </div>
                                    <div className='col'>
                                        <div className='font-weight-medium'>
                                            { statistics.wagers } wagers
                                        </div>
                                        <div className='text-muted'>
                                            From { statistics.users } unique users
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <h3 className='card-title'>
                                Historical statistics
                            </h3>
                            <div className='ms-auto'>
                                <div className='dropdown'>
                                    <a
                                        className={ `dropdown-toggle text-muted ${ loading ? 'disabled' : 'cursor-pointer' }` }
                                        data-bs-toggle='dropdown'
                                        aria-expanded='false'
                                        id='dropdown-game-list'
                                    >
                                        { PERIODS[ period ] }
                                    </a>
                                    <div className='dropdown-menu dropdown-menu-end'>
                                        { Object.entries(PERIODS).map(([ key, readable ]) => (
                                            <a
                                                className={ `dropdown-item ${ period === key ? 'active' : 'cursor-pointer' }` }
                                                onClick={ event => setHistoricalPeriod(key, event) }
                                                key={ key }
                                            >
                                                { readable }
                                            </a>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            { loading ? (
                                <span style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#ffffff',
                                    position: 'absolute',
                                    display: 'flex',
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    top: 0
                                }}>
                                    Loading<span className='animated-dots'></span>
                                </span>
                            ) : '' }
                            <div className='card mb-3 text-center'>
                                <div className='card-body'>
                                    <div className='row row-cards'>
                                        <div className='col-sm-6 col-md-4'>
                                            <div className='font-weight-medium'>
                                                ${ Number(totals.wagered / 100).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }) } wagered
                                            </div>
                                            <div className='text-muted'>
                                                Amount users have wagered
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4'>
                                            <div className='font-weight-medium'>
                                                ${ Number(totals.profit / 100).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }) } profit
                                            </div>
                                            <div className='text-muted'>
                                                Amount of profit for us
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-md-4'>
                                            <div className='font-weight-medium'>
                                                { totals.wagers } wagers
                                            </div>
                                            <div className='text-muted'>
                                                From { totals.users } unique users
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    background: '#ffffff',
                                    position: 'relative'
                                }}
                                className='chart-lg'
                                id='chart-game-historical'
                            />
                            { !(loading) && !(historical.length) ? (
                                <span style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#ffffff',
                                    position: 'absolute',
                                    display: 'flex',
                                    zIndex: 20,
                                    bottom: 0,
                                    right: 0,
                                    left: 0,
                                    top: 0,
                                }}>
                                    No data available for given time range
                                </span>
                            ) : '' }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(GameStatistics);