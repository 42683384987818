import api from 'api';
import React from 'react';
import moment from 'moment';

import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const Ban = ({ loading, setLoading, setError, query, setQuery, user, getUser, currencies }) => {
    const {
        handleSubmit,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    const onCloseModal = useCallback(() => {
        query.delete('ban');
        setQuery(query);
    }, [ query ]);

    const onSubmit = ({ reason, duration: durationAsString }) => {
        setLoading(true);

        const duration = durationAsString.split(',').reduce((total, duration) => {
            const [ amount, period ] = duration.trim().split(' ');

            return total + moment
                .duration(amount, period.endsWith('s') ? period : `${ period }s`)
                .asMilliseconds();
        }, Date.now());

        return api
            .banUser(
                user.userID,
                reason,
                duration
            )
            .then(() => {
                return getUser();
            })
            .then(() => {
                onCloseModal();
            })
            .catch(error => {
                console.warn('Failed to ban user');
                console.error(error);
                setError(error);
            });
    };

    return (
        <Modal
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Ban { user.username }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Reason
                </label>
                <input
                    className={ `form-control ${ formState.errors.reason ? 'is-invalid' : '' }` }
                    disabled={ loading }
                    placeholder='User is a massive nonce'
                    type='text'
                    { ...register('reason', {
                        required: true,
                        minLength: 2,
                        maxLength: 128
                    }) }
                />
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Duration
                </label>
                <input
                    className={ `form-control ${ formState.errors.duration ? 'is-invalid' : '' }` }
                    disabled={ loading }
                    placeholder='3 weeks, 2 days'
                    type='text'
                    { ...register('duration', {
                        required: true,
                        validate: value => !(value.split(',').some(duration => {
                            return !(/^\d* (seconds?|minutes?|hours?|days?|weeks?|months?|years?)$/.test(duration.trim()));
                        }))
                    }) }
                />
            </div>
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (loading || !(formState.isDirty) || !(formState.isValid)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Banning user<span className='animated-dots' />
                        </span>
                    ) : 'Ban' }
                </button>
            </div>
        </Modal>
    );
};

export default Ban;