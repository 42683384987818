import React from 'react';
import classnames from 'classnames';

import { useMemo } from 'react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';

const range = (start, end) => {
    const length = end - start + 1;

    return Array.from({ length }, (_, idx) => {
        return idx + start;
    });
};

const DOTS = '...';

const usePagination = ({
    totalCount,
    pageSize,
    siblingCount = 1,
    currentPage
}) => {
    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);
        const totalPageNumbers = siblingCount + 5;

        if(totalPageNumbers >= totalPageCount)
            return range(1, totalPageCount);

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if(!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + (2 * siblingCount);
            const leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if(shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + (2 * siblingCount);
            const rightRange = range(
                totalPageCount - rightItemCount + 1,
                totalPageCount
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if(shouldShowLeftDots && shouldShowRightDots) {
            const middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [ totalCount, pageSize, siblingCount, currentPage ]);

    return paginationRange;
};

const Pagination = ({
    onPageChange: onPageChangeParent,
    siblingCount = 1,
    currentPage,
    totalCount,
    className,
    pageSize,
    disabled
}) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if(currentPage === 0 || paginationRange.length < 2)
        return null;

    const onNext = event => {
        onPageChange(event, currentPage + 1);
    };

    const onPrevious = event => {
        onPageChange(event, currentPage - 1);
    };

    const onPageChange = (event, pageNumber) => {
        event.preventDefault();
        onPageChangeParent(pageNumber);
    };

    const lastPage = paginationRange[ paginationRange.length - 1 ];

    return (
        <ul className={ classnames('pagination m-0 ms-auto', {
            [ className ]: className
        }) }>
            <li className={ classnames('page-item', {
                disabled: currentPage === 1 || disabled
            }) }>
                <a
                    className='page-link'
                    href='#'
                    tabIndex='-1'
                    aria-disabled='true'
                    onClick={ onPrevious }
                >
                    <IconChevronLeft />
                    prev
                </a>
            </li>
            { paginationRange.map((pageNumber, index) => {
                if(pageNumber === DOTS) {
                    return (
                        <li className='page-item' key={ index }>
                            <a className='page-link'>
                                &#8230;
                            </a>
                        </li>
                    );
                }

                return (
                    <li
                        className={ classnames('page-item', {
                            active: pageNumber === currentPage,
                            disabled
                        }) }
                        key={ index }
                    >
                        <a className='page-link' href='#' onClick={ event => onPageChange(event, pageNumber)} >
                            { Number(pageNumber).toLocaleString() }
                        </a>
                    </li>
                );
            }) }
            <li className={ classnames('page-item', {
                disabled: currentPage === lastPage || disabled
            }) }>
                <a
                    className='page-link'
                    href='#'
                    tabIndex='-1'
                    aria-disabled='true'
                    onClick={ onNext }
                >
                    next
                    <IconChevronRight />
                </a>
            </li>
        </ul>
    );
};

export default Pagination;