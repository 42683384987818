import React from 'react';
import api from 'api';

import { memo, useLayoutEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IconCheck } from '@tabler/icons';
import { hasPermissions, Permissions, prettifyPermission, traversePermissions } from 'utils/permissions';

const PermissionsModal = ({ loading, error, setLoading, setError, getUser, query, setQuery, user }) => {
    const [ roles, setRoles ] = useState({});
    const [ inheritedPermissions, setInheritedPermissions ] = useState(0);

    const {
        handleSubmit,
        resetField,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const role = watch('role');

    useLayoutEffect(() => {
        if(!(user))
            return;

        resetField('role', { defaultValue: user[ 'permissions:role' ] || 'false' });
    }, [ user, roles ]);

    useLayoutEffect(() => {
        if(!(user))
            return;

        Object
            .entries(Permissions)
            .forEach(([ name, permission ]) => {
                const hasPermission = !!hasPermissions(user[ 'permissions:individual' ], permission);

                resetField(`permission.${ name }`, {
                    defaultValue: hasPermission
                });
            });

        setInheritedPermissions(
            traversePermissions(roles, role !== 'false' && role, true)
        );
    }, [ role, user ]);

    useLayoutEffect(() => {
        setLoading(true);

        api.getRoles().then(roles => {
            setRoles(roles);
            setLoading(false);
        }).catch(error => {
            console.warn('Failed to fetch list of roles');
            console.error(error);

            setLoading(false);
            setError(error);
        });
    }, [ ]);

    const onCloseModal = useCallback(() => {
        query.delete('permissions');
        setQuery(query);
    }, [ query ]);

    const onSubmit = async ({ role, permission: individual }) => {
        setLoading(true);

        const permissions = Object
            .entries(individual)
            .reduce((permissions, [ key, value ]) => {
                if(!(value))
                    return permissions;

                return permissions | Permissions[ key ];
            }, 0);

        return api
            .setUserPermissions(
                user.userID,
                role === 'false' ? null : role,
                permissions
            )
            .then(() => {
                return getUser();
            })
            .catch(error => {
                console.warn('Failed to set user permissions');
                console.error(error);
                setError(error);
            });
    };

    return Object.keys(roles).length ? (
        <Modal
            dialogClassName='modal-lg edit-role-modal modal-dialog-scrollable'
            animation={ false }
            onHide={ onCloseModal }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Set permissions for { user.username }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body inherits'>
                <label className='form-label'>
                    Role
                </label>
                <select
                    className='form-select'
                    disabled={ loading }
                    { ...register('role') }
                >
                    { Object.keys(roles).map(name => (
                        <option
                            disabled={ role === name }
                            value={ name }
                            key={ name }
                        >
                            { name }
                        </option>
                    )) }
                    <option value='false'>
                        member
                    </option>
                </select>
            </div>
            <div className='modal-body permissions'>
                <div className='row row-cards'>
                    { Object.entries(Permissions).map(([ name, permission ]) => (
                        <div className='col-md-6' key={ name }>
                            <button
                                className={ `btn w-100 d-flex align-items-center permission ${ hasPermissions(inheritedPermissions, permission) ? 'selected disabled' : '' }` }
                                disabled={ loading }
                                type='button'
                            >
                                <input
                                    className='form-selectgroup-input'
                                    type='checkbox'
                                    { ...register(`permission.${ name }`) }
                                />
                                <span>
                                    { prettifyPermission(name) }
                                </span>
                                <div className='ms-auto btn btn-icon disabled bg-green' >
                                    <IconCheck />
                                </div>
                            </button>
                        </div>
                    )) }
                </div>
            </div>
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (loading || !formState.isDirty) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Saving<span className='animated-dots' />
                        </span>
                    ) : 'Save changes' }
                </button>
            </div>
        </Modal>
    ) : '';
};

export default memo(PermissionsModal);