import React from 'react';

import { memo, useLayoutEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IconCheck } from '@tabler/icons';
import { hasPermissions, Permissions } from 'utils/permissions';

const CreateRole = ({ loading, roles, error, onCloseModal, onCreate, prettifyPermission, traversePermissions }) => {
    const [ inheritedPermissions, setInheritedPermissions ] = useState(0);

    const {
        handleSubmit,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const permissions = watch('permission');
    const inherits = watch('inherits', 'false');
    const role = watch('role', '');

    useLayoutEffect(() => {
        setInheritedPermissions(
            traversePermissions(roles, inherits !== 'false' && inherits)
        );
    }, [ roles, inherits ]);

    const onSubmit = async () => {
        onCreate({
            permissions: Object
                .entries(permissions)
                .reduce((permissions, [ name, hasPermission ]) => {
                    if(!(hasPermission))
                        return permissions;

                    return permissions | Permissions[ name ];
                }, 0),

            inherits: inherits !== 'false' && inherits,
            role: role.toLowerCase()
        });
    };

    return (
        <Modal
            dialogClassName='modal-lg edit-role-modal modal-dialog-scrollable'
            animation={ false }
            onHide={ onCloseModal }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Create role
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body name'>
                <label className='form-label'>
                    Name
                </label>
                <input
                    placeholder='Role name'
                    className={ `form-control ${ role && role.length && formState.errors.role ? 'is-invalid' : '' }` }
                    disabled={ loading }
                    type='text'
                    { ...register('role', {
                        validate: role => !(role.toLowerCase() in roles),
                        required: true,
                        pattern: /^[a-zA-Z]{4,18}$/
                    }) }
                />
            </div>
            <div className='modal-body inherits'>
                <label className='form-label'>
                    Inherits
                </label>
                <select
                    className='form-select'
                    disabled={ loading }
                    { ...register('inherits', {
                        value: 'false'
                    }) }
                >
                    { Object.keys(roles).map(name => (
                        <option value={ name } key={ name }>
                            { name }
                        </option>
                    )) }
                    <option value='false'>
                        No inheritance
                    </option>
                </select>
            </div>
            <div className='modal-body permissions'>
                <div className='row row-cards'>
                    { Object.entries(Permissions).map(([ name, permission ]) => (
                        <div className='col-md-6' key={ name }>
                            <button
                                className={ `btn w-100 d-flex align-items-center permission ${ hasPermissions(inheritedPermissions, permission) ? 'selected disabled' : '' }` }
                                disabled={ loading }
                                type='button'
                            >
                                <input
                                    className='form-selectgroup-input'
                                    type='checkbox'
                                    { ...register(`permission.${ name }`) }
                                />
                                <span>
                                    { prettifyPermission(name) }
                                </span>
                                <div className='ms-auto btn btn-icon disabled bg-green' >
                                    <IconCheck />
                                </div>
                            </button>
                        </div>
                    )) }
                </div>
                { error ? (
                    <div className='card bg-red-lt mt-3'>
                        <div className='card-body'>
                            <h3 className='card-title mb-0'>
                                Failed to create role
                            </h3>
                            <p className='text-muted'>
                                { error }
                            </p>
                        </div>
                    </div>
                ) : '' }
            </div>
            { error ? (
                <div className='modal-body message'>
                    <div className='card bg-red-lt text-center'>
                        <div className='card-body'>
                            <h3 className='card-title mb-0'>
                                Failed to create role
                            </h3>
                            <p className='text-muted'>
                                { error }
                            </p>
                        </div>
                    </div>
                </div>
            ) : '' }
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (!(formState.isValid) || loading || !(formState.isDirty)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Creating<span className='animated-dots' />
                        </span>
                    ) : 'Create role' }
                </button>
            </div>
        </Modal>
    );
};

export default memo(CreateRole);