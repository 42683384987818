import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

const Authentication = ({ setLoading, getConfig, loading, config, show }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('auth', { defaultValue: JSON.parse(JSON.stringify(config?.auth || {})) });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        for(const key in updatedConfig.auth) {
            if(updatedConfig.auth[ key ] !== config.auth[ key ])
                parameters[ key ] = updatedConfig.auth[ key ];
        }

        setLoading(true);

        api
            .setConfigAuthentication(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    const auth = watch('auth'); // Again, don't even ask.
    const isDirty = JSON.stringify(config?.auth) !== JSON.stringify(auth);

    return (
        <div className={ !(show) ? 'd-none' : 'captchas' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Authentication
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Steam
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('auth.steam') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Discord
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('auth.discord') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Facebook
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('auth.facebook') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Google
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('auth.google') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Twitch
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('auth.twitch') }
                            />
                        </label>
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Authentication;