import React from 'react';
import GameStatistics from './GameStatistics';

const Hub = ({ site }) => {
    return (
        <>
            <div className='container-xl'>
                <div className='page-header'>
                    <div className='row g-2 align-items-center'>
                        <div className='col'>
                            <h2 className='page-title'>
                                Hub
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='page-body'>
                <div className='container-xl'>
                    <div className='row row-cards'>
                        <GameStatistics
                            site={ site }
                            game='hub'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hub;