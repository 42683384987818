import React, { memo } from 'react';
import ReactDOM from 'react-dom';

const container = document.getElementById('modals');

const Modal = props => {
    return ReactDOM.createPortal(
        <div className='modal modal-blur fade' id={ `modal-${ props.name }` } tabIndex='-1' role='dialog' aria-hidden='true'>
            <div className='modal-dialog modal-dialog-centered' role='document'>
                <div className='modal-content'>
                    { props.children }
                </div>
            </div>
        </div>,
        container
    );
};

export default memo(Modal);