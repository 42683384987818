import React, { useCallback, useEffect, useState } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import api from 'api';
import sites from 'sites';

import { useSearchParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { IconBrandSteam } from '@tabler/icons';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { popupWindow } from 'utils';

import './Auth.scss';

const Auth = ({ site }) => {
    const [ isPopupOpen, setPopupOpen ] = useState(false);
    const [ popupError, setPopupError ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ query, setQuery ] = useSearchParams();
    const captchaRef = useRef();

    const {
        handleSubmit,
        clearErrors,
        formState,
        setError,
        register
    } = useForm({ mode: 'onChange' });

    useEffect(() => {
        if(!site || !(site in sites))
            return;

        if(!query.has('auth'))
            return;

        const {
            preAuthToken,
            refreshToken,
            authToken,
            error,
        } = JSON.parse(query.get('auth'));

        if(error || preAuthToken) {
            if(preAuthToken)
                setPopupError('Account registration is not available');
            else setPopupError(error);

            setPopupOpen(true);
            query.delete('auth');

            return setQuery(query);
        }

        localStorage.setItem(`auth.${ site }`, refreshToken);

        api.setRefreshToken(refreshToken);
        api.storeAuthToken(authToken);
        api.connect();
        query.delete('auth');

        return setQuery(query);
    }, [ query, site ]);

    const openPopup = useCallback(() => {
        setPopupOpen(true);
        popupWindow(`${ sites[ site ].api }/auth/steam?redirect=admin`, 'auth', 800, 600);

        const onAuthCallback = event => {
            if(event.data.type !== 'auth')
                return;

            const {
                preAuthToken,
                refreshToken,
                authToken,
                error,
            } = event.data.payload;

            if(preAuthToken)
                return setPopupError('Account registration is not available');

            if(error)
                return setPopupError(error);

            localStorage.setItem(`auth.${ site }`, refreshToken);

            api.setRefreshToken(refreshToken);
            api.storeAuthToken(authToken);
            api.connect();
        };

        window.addEventListener('message', onAuthCallback);

        return () => {
            window.removeEventListener('message', onAuthCallback);
        };
    }, [ site ]);

    const onSubmit = async ({ username, twoFactorCode, password }) => {
        setIsLoading(true);
        captchaRef.current.reset();

        const captcha = await captchaRef.current
            .executeAsync();

        return api
            .authenticate({
                twoFactorCode,
                password,
                username,
                captcha,
                site
            })
            .catch(error => {
                clearErrors('generic');

                switch (error) {
                    case 'MalformedRequest': return setError('username', { type: 'custom', message: 'User not found' });
                    case 'UserIncorrectPassword': return setError('password', { type: 'custom', message: 'Incorrect password' });
                    case 'CaptchaFailed': return setError('generic', { type: 'custom', message: 'Captcha validation failed' });
                    case 'UserTwoFactorInvalid': return setError('twoFactorCode', { type: 'custom', message: 'Incorrect code' });

                    default: return setError('generic', { type: 'custom', message: `Unknown error occurred: ${ error }` });
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className='container-tight auth-container'>
                <div className='page-body'>
                    <form className='card card-md' autoComplete='on' onSubmit={ handleSubmit(onSubmit) }>
                        <div className='card-body'>
                            <h2 className='card-title text-center mb-4'>
                                Login to your account
                            </h2>
                            <div className='mb-3'>
                                <label className='form-label'>
                                    Username
                                    <span className='form-label-description is-invalid'>
                                        { formState.errors?.username?.message }
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className={ `form-control ${ formState.errors.username ? 'is-invalid' : '' }` }
                                    placeholder='Enter username'
                                    autoComplete='username'
                                    autoFocus
                                    disabled={ formState.isSubmitting || isLoading }
                                    { ...register('username', {
                                        required: true,
                                        pattern: /^[a-zA-Z-._0-9]{4,32}$/
                                    }) }
                                />
                            </div>
                            <div className='mb-3'>
                                <label className='form-label'>
                                    Two Factor Code
                                    <span className='form-label-description is-invalid'>
                                        { formState.errors?.twoFactorCode?.message }
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className={ `form-control ${ formState.errors.twoFactorCode ? 'is-invalid' : '' }` }
                                    placeholder='Enter six digits'
                                    disabled={ formState.isSubmitting || isLoading }
                                    { ...register('twoFactorCode', {
                                        pattern: /^[\d]{6}$/
                                    }) }
                                />
                            </div>
                            <div className='mb-2'>
                                <label className='form-label'>
                                    Password
                                    <span className='form-label-description is-invalid'>
                                        { formState.errors?.password?.message }
                                    </span>
                                </label>
                                <div className='input-group input-group-flat'>
                                    <input
                                        type='password'
                                        className={ `form-control ${ formState.errors.password ? 'is-invalid' : '' }` }
                                        placeholder='Password'
                                        autoComplete='current-password'
                                        disabled={ formState.isSubmitting || isLoading }
                                        { ...register('password', {
                                            required: true,
                                            pattern: /^.{6,256}$/
                                        }) }
                                    />
                                </div>
                            </div>
                            <ReCaptcha
                                ref={ captchaRef }
                                size='invisible'
                                sitekey={ process.env.RAZZLE_RECAPTCHA_KEY }
                            />
                            { formState.errors.generic ? (
                                <div className='card bg-red-lt mt-4'>
                                    <div className='card-body'>
                                        <p className='text-muted'>
                                            { formState.errors.generic.message }
                                        </p>
                                    </div>
                                </div>
                            ) : '' }
                            <div className='form-footer'>
                                <input
                                    type='submit'
                                    className='btn btn-primary w-100'
                                    disabled={ isLoading || formState.isSubmitting || (!(formState.isValid) && !('generic' in formState.errors)) }
                                    value='Sign in'
                                />
                            </div>
                        </div>
                        <div className='hr-text'>
                            or
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col'>
                                    <a className={
                                        `btn btn-white w-100 ${ (isLoading || formState.isSubmitting ) ? 'disabled' : '' }`
                                    } onClick={ openPopup }>
                                        <IconBrandSteam />
                                        Login with Steam
                                    </a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                { isPopupOpen ? (
                    <Modal
                        dialogClassName='modal-sm'
                        onHide={ () => setPopupOpen(false) }
                        show={ true }
                        centered
                    >
                        <button
                            aria-label='Close'
                            className='btn-close'
                            onClick={ () => setPopupOpen(false) }
                            type='button'
                        />
                        <div className={ `modal-status bg-${ popupError ? 'danger' : 'primary' }` } />
                        <div className='modal-body text-center py-4 pb-3'>
                            <IconBrandSteam className={ `icon mb-2 text-${ popupError ? 'danger' : 'primary' } icon-lg` } />
                            { popupError ? (
                                <>
                                    <h3>
                                        Authentication failed
                                    </h3>
                                    <div className='mb-2'>
                                        { popupError }
                                    </div>
                                    <div className='text-muted'>
                                        Would you like to <a href={ `${ sites[ site ].api }/auth/steam?redirect=admin` } onClick={ openPopup }>try again</a>?
                                    </div>
                                </>
                            ) : (
                                <>
                                    <h3>
                                        Signing you in
                                    </h3>
                                    <div className='mb-2'>
                                        A popup window should have opened with the Steam login page.
                                    </div>
                                    <div className='text-muted'>
                                        Your browser may have blocked the popup. You can <a href={ `${ sites[ site ].api }/auth/steam?redirect=admin` }>click here</a> to sign in the old-fashioned way.
                                    </div>
                                </>
                            ) }
                        </div>
                    </Modal>
                ) : null }
            </div>
        </>
    );
};

export default Auth;