import React from 'react';

import { Modal } from 'react-bootstrap';
import { IconAlertTriangle } from '@tabler/icons';

const DeletePromoCode = ({ loading, promoCode, error, onCloseModal, onDelete }) => (
    <Modal
        dialogClassName='modal-sm'
        animation={ false }
        onHide={ onCloseModal }
        show={ true }
        centered
    >
        { !(loading) ? (
            <button
                aria-label='Close'
                className='btn-close'
                onClick={ onCloseModal }
                type='button'
            />
        ) : '' }
        <div className='modal-status bg-danger' />
        <div className='modal-body text-center py-4 pb-3'>
            <IconAlertTriangle className='icon mb-2 icon-lg text-red' />
            <h3>
                Are you sure?
            </h3>
            <div className='text-muted'>
                Do you really want to cancel promo code <strong>{ promoCode }</strong>?
                What you&apos;re doing cannot be undone.
            </div>
            { error ? (
                <div className='card bg-red-lt mt-3'>
                    <div className='card-body'>
                        <h3 className='card-title mb-0'>
                            Failed to cancel promo code
                        </h3>
                        <p className='text-muted'>
                            { error }
                        </p>
                    </div>
                </div>
            ) : '' }
        </div>
        <div className='modal-footer'>
            <button
                className={ `btn btn-danger w-100 ${ loading ? 'disabled' : '' }` }
                onClick={ onDelete }
            >
                { loading ? (
                    <span>
                        Cancelling<span className='animated-dots' />
                    </span>
                ) : `Cancel ${ promoCode }` }
            </button>
        </div>
    </Modal>
);

export default DeletePromoCode;