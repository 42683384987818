import React from 'react';
import store from 'store';
import App from 'components/modules/App';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(
    document.getElementById('root')
);

root.render(
    <Provider store={ store }>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

if(module.hot)
    module.hot.accept();
