import React from 'react';
import EnvironmentLabel from 'components/ui/EnvironmentLabel';
import sites from 'sites';
import { connect } from 'react-redux';

const Footer = ({ site, error, loading }) => {
    const siteMeta = sites[ site ];

    const prefix = error ?
        'Failed to load' : loading ?
            'Currently loading' :
            'Currently viewing';

    return (
        <footer className='footer footer-transparent d-print-none'>
            <div className='container-xl'>
                <div className='row text-center align-items-center flex-row-reverse'></div>
                <div className='col-12 col-lg-auto mt-lg-0'>
                    <ul className='list-inline list-inline-dots mb-0'>
                        <li className='list-inline-item'>
                            { prefix }&nbsp;
                            <strong>
                                <span className='pe-1'>{ siteMeta.name }</span>
                                <EnvironmentLabel env={ siteMeta.env } />
                            </strong>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default connect(state => ({
    error: state.app.error,
    loading: state.app.loading
}))(Footer);