import React from 'react';
import api from 'api';
import DeleteRole from './modals/DeleteRole';
import EditRole from './modals/EditRole';
import CreateRole from './modals/CreateRole';
import Masonry from 'masonry-layout/dist/masonry.pkgd';

import { useLayoutEffect, useState, useEffect } from 'react';
import { IconCheck, IconPencil, IconX } from '@tabler/icons';
import { Permissions, prettifyPermission, traversePermissions } from 'utils/permissions';
import { Link, useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { ROLE_COLOURS } from '../Users';

import './Roles.scss';

const Roles = ({ site }) => {
    const [ showCreateRoleModal, setShowCreateRoleModal ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ roles, setRoles ] = useState({});
    const [ error, setError ] = useState(false);
    const [ query, setQuery ] = useSearchParams();
    const [ role, setRole ] = useState(false);

    const onCloseModal = useCallback(() => {
        if(loading)
            return;

        query.delete('delete');
        query.delete('role');
        query.delete('edit');

        setQuery(query);
    }, [ query, loading ]);

    useEffect(() => {
        const role = query.get('role');

        if(query.has('delete') && !(role)) {
            query.delete('delete');
            setQuery(query);
            return setRole(false);
        }

        if(!(role))
            return setRole(false);

        if(Object.keys(roles).length && !(role in roles)) {
            query.delete('role');
            setQuery(query);
            return setRole(false);
        }

        if(Object.keys(roles).length)
            setRole(role);
    }, [ roles, query ]);

    const fetchRoles = () => {
        return api
            .getRoles()
            .then(roles => {
                const listOfRoles = Object
                    .entries(roles)
                    .map(([ name, role ]) => {
                        const inherited = traversePermissions(roles, role.inherits, true);

                        return {
                            individual: role.permissions,
                            ...role,

                            permissions: Object
                                .entries(Permissions)
                                .filter(([ name, value ]) => {
                                    return (role.permissions | inherited) & value;
                                })
                                .sort((a, b) => {
                                    return b[ 1 ] - a[ 1 ];
                                })
                                .map(([ name ]) => name),

                            name
                        };
                    })
                    .sort((a, b) => {
                        return b.individual - a.individual;
                    })
                    .reduce((roles, role) => {
                        roles[ role.name ] = role;
                        return roles;
                    }, {});

                setRoles(listOfRoles);
                setLoading(false);
                setError(false);
            })
            .catch(error => {
                console.warn('Failed to fetch list of roles');
                console.error(error);

                setError(error);
                setLoading(false);
            });
    };

    useLayoutEffect(() => {
        fetchRoles();
    }, [ site, query ]);

    const onDelete = useCallback(() => {
        setLoading(true);

        api
            .deleteRole(role)
            .then(() => {
                return fetchRoles();
            })
            .then(() => {
                query.delete('role');
                query.delete('delete');

                setQuery(query);
            })
            .catch(error => {
                console.warn('Failed to delete role');
                console.error(error);

                switch (error) {
                    case 'INVALID_ROLE': setError('Role does not exist'); break;
                    case 'NON_DELETABLE_ROLE': setError('Cannot delete system roles'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
            });
    }, [ query, role ]);

    const onEdit = useCallback(({ inherits, permissions }) => {
        setLoading(true);

        return api
            .updateRole(
                role,
                inherits,
                permissions
            )
            .then(() => {
                return fetchRoles();
            })
            .then(() => {
                return true;
            })
            .catch(error => {
                console.warn('Failed to edit role');
                console.error(error);

                switch (error) {
                    case 'CIRCULAR_INHERITANCE': setError('Inherited role creates circular dependency'); break;
                    case 'INVALID_INHERITANCE': setError('Inherited role does not exist'); break;
                    case 'INVALID_PERMISSIONS': setError('Invalid permissions specified'); break;
                    case 'INVALID_ROLE': setError('Role does not exist'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
                return false;
            });
    }, [ query, role ]);

    const onCreate = useCallback(({ inherits, permissions, role }) => {
        setLoading(true);

        return api
            .createRole(
                role,
                inherits,
                permissions
            )
            .then(() => {
                return fetchRoles();
            })
            .then(() => {
                query.set('role', role);

                setQuery(query);
                setShowCreateRoleModal(false);
            })
            .catch(error => {
                console.warn('Failed to create role');
                console.error(error);

                switch (error) {
                    case 'INVALID_INHERITANCE': setError('Inherited role does not exist'); break;
                    case 'INVALID_PERMISSIONS': setError('Invalid permissions specified'); break;
                    case 'INVALID_ROLE': setError('Invalid role name provided'); break;
                    case 'ROLE_EXISTS': setError('Provided role name is already in use'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
            });
    }, [ query, role ]);

    useLayoutEffect(() => {
        if(!(Object.keys(roles).length))
            return;

        const masonry = new Masonry( '.masonry', {
            percentPosition: true
        });

        return () => {
            masonry.destroy();
        };
    }, [ roles ]);

    return (
        <>
            <div className='container-xl'>
                <div className='page-header'>
                    <div className='row g-2 align-items-center'>
                        <div className='col'>
                            <div className='page-pretitle'>
                                Users
                            </div>
                            <h2 className='page-title'>
                                Roles
                            </h2>
                        </div>
                        <div className='col-12 col-md-auto ms-auto'>
                            <button
                                className='btn btn-primary'
                                disabled={ loading }
                                onClick= { () => setShowCreateRoleModal(true) }
                            >
                                Create role
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='page-body roles'>
                <div className='container-xl'>
                    <div className='row row-cards placeholder-glow masonry'>
                        { (loading && !(Object.keys(roles).length)) ? Array.from({ length: 3 }).map((_, index) => (
                            <div className='col-md-6 col-lg-4' key={ index }>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='card-title'>
                                            <div className='d-flex align-items-center'>
                                                <span className='placeholder placeholder-xs col-6' />
                                                <span className='placeholder placeholder-xs col-1 ms-auto' />
                                            </div>
                                        </div>
                                        <p className='text-muted'>
                                            <span className='placeholder placeholder-xs col-9' />
                                            <span className='placeholder placeholder-xs col-6' />
                                            <span className='placeholder placeholder-xs col-8' />
                                            <span className='placeholder placeholder-xs col-5' />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )) : Object.values(roles).map(role => (
                            <div className='col-md-6 col-lg-4' key={ role.name }>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className={ `card-title mb-1 ${ ROLE_COLOURS[ role.name ] || '' }` }>
                                            <div className='d-flex align-items-center'>
                                                <span className='role-name'>
                                                    { role.name }
                                                </span>
                                                <div className='card-actions btn-actions'>
                                                    <Link to={ `/${ site }/users/roles?role=${ role.name }` } className='btn-action'>
                                                        <IconPencil />
                                                    </Link>
                                                    { (role.name in ROLE_COLOURS) ? '' : (
                                                        <Link to={ `/${ site }/users/roles?role=${ role.name }&delete` } className='btn-action text-red'>
                                                            <IconX />
                                                        </Link>
                                                    ) }
                                                </div>
                                            </div>
                                        </div>
                                        { role.inherits ? (
                                            <div className='text-muted mb-3'>
                                                Inherits <strong>{ role.inherits }</strong>
                                            </div>
                                        ) : '' }
                                        <ul className='list-unstyled space-y-1'>
                                            { role.permissions
                                                .slice(0, role.permissions.length === 4 ? 4 : 3)
                                                .map(permission => (
                                                    <li key={ permission } className='text-nowrap text-truncate'>
                                                        <IconCheck className='icon text-green me-2' />
                                                        { prettifyPermission(permission) }
                                                    </li>
                                                ))
                                            }
                                            { role.permissions.length > 4 ? (
                                                <li>
                                                    ..and { role.permissions.length - 3 } more
                                                </li>
                                            ) : '' }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
            { ((role in roles) && query.has('delete')) ? (
                <DeleteRole
                    onCloseModal={ onCloseModal }
                    onDelete={ onDelete }
                    loading={ loading }
                    role={ role }
                    error={ error }
                />
            ) : ((role in roles) && !(query.has('delete'))) ? (
                <EditRole
                    traversePermissions={ traversePermissions }
                    prettifyPermission={ prettifyPermission }
                    onCloseModal={ onCloseModal }
                    onEdit={ onEdit }
                    loading={ loading }
                    roles={ roles }
                    error={ error }
                    role={ role }
                />
            ) : showCreateRoleModal ? (
                <CreateRole
                    traversePermissions={ traversePermissions }
                    prettifyPermission={ prettifyPermission }
                    onCloseModal={ () => setShowCreateRoleModal(false) }
                    onCreate={ onCreate }
                    loading={ loading }
                    error={ error }
                    roles={ roles }
                />
            ) : '' }
        </>
    );
};

export default Roles;