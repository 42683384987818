import React from 'react';
import formatDate from 'dateformat';
import api from 'api';

import { useEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import Pagination from 'components/ui/Pagination/Pagination';
import { Link } from 'react-router-dom';

const DEFAULT_PAGINATION = {
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    total: 0,
    from: 0,
    to: 0
};

const ViewPromoCode = ({ promoCode, onCloseModal, site }) => {
    const [ pagination, setPagination ] = useState(DEFAULT_PAGINATION);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ users, setUsers ] = useState([]);

    const setCurrentPage = useCallback(page => {
        setLoading(true);

        api
            .getPromoCodeUsers(promoCode.promoCodeID, page)
            .then(({ data: users, pagination }) => {
                setPagination(pagination);
                setLoading(false);
                setUsers(users);
            })
            .catch(error => {
                console.warn('Failed to fetch promo codes');
                console.error(error);

                setPagination(DEFAULT_PAGINATION);
                setLoading(false);
                setError(error);
                setUsers([]);
            });
    }, [ promoCode ]);

    useEffect(() => {
        setCurrentPage(1);
    }, [ site ]);

    return (
        <Modal
            dialogClassName='modal-lg view-promo-code-modal'
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Viewing promo code { promoCode.promoCodeID }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body stats'>
                <div className='container-xl'>
                    <div className='row align-items-center text-center'>
                        <div className='col'>
                            <h3>
                                { formatDate(promoCode.createdAt, 'mmmm dd, yyyy') }
                            </h3>
                            <span className='text-muted'>
                                Created at
                            </span>
                        </div>
                        <div className='col'>
                            <h3>
                                ${ Number(promoCode[ 'reward:amount' ] / 100).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) } { promoCode[ 'reward:currency' ] }
                            </h3>
                            <span className='text-muted'>
                                Reward
                            </span>
                        </div>
                        <div className='col'>
                            <h3>
                                { promoCode.users } of { promoCode.maxUsers }
                            </h3>
                            <span className='text-muted'>
                                Redemptions
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-body'>
                <div className='row row-deck row-cards'>
                    { users.map(user => (
                        <div className='col-md-6' key={ user.userID }>
                            <Link to={ `/${ site }/users?user=${ user.userID }` } className='card'>
                                <div className='card-body d-flex lh-1 text-reset'>
                                    <span
                                        className='avatar avatar-sm'
                                        style={{ backgroundImage: `url(${ user[ 'avatar:image' ] })` }}
                                    />
                                    <div className='d-none d-xl-block ps-2'>
                                        <div>
                                            { user.username }
                                        </div>
                                        <div className='mt-1 small text-muted'>
                                            { user[ 'permissions:role' ] || 'member' }
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )) }
                </div>
            </div>
            <div className='modal-footer d-flex align-items-center'>
                <p className='m-0 text-muted'>
                    Showing <strong>
                        { pagination.to ? (pagination.from + 1) : 0 }
                    </strong> to <strong>
                        { pagination.to }
                    </strong> of <strong>
                        { pagination.total }
                    </strong> users
                </p>
                <Pagination
                    onPageChange={ page => setCurrentPage(page) }
                    currentPage={ pagination.currentPage }
                    totalCount={ pagination.total }
                    pageSize={ 10 }
                    disabled={ loading }
                />
            </div>
        </Modal>
    );
};

export default ViewPromoCode;