import React from 'react';
import pages from 'components/pages';
import Navigation from 'components/modules/Navigation';
import Header from 'components/modules/Header';
import Footer from 'components/modules/Footer';
import sites from 'sites';
import LoadingScreen from '../LoadingScreen';
import api from 'api';
import Logger from 'utils/Logger.js';

import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { setConfig, setError, setFeatures, setLoading, setUser, setItemList } from 'store/actions/app';
import { useNetworkConnection } from 'use-network-connection';
import { hasPermissions } from 'utils/permissions';
import { match } from 'path-to-regexp';

import '@tabler/core/dist/css/tabler.css';
import '@tabler/core/dist/js/tabler';
import 'jquery/dist/jquery';
import './App.scss';

const logger = new Logger('app');

const matchLocation = match('/:site?/:pages*', { decode: decodeURIComponent });

const App = ({ error, loading, user }) => {
    const { isOnline } = useNetworkConnection();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [ site, setSite ] = useState(false);
    const [ page, setPage ] = useState(false);

    useEffect(() => {
        const {
            pages = [],
            site
        } = matchLocation(location.pathname).params;

        if(!site)
            setSite(Object.keys(sites)[ 0 ]);
        else setSite(site);

        setPage(pages.join('/'));
    }, [ location.pathname ]);

    useEffect(() => {
        if(!site)
            return;

        if(!(site in sites))
            return navigate(`/${ Object.keys(sites)[ 0 ] }/dashboard/statistics${ location.search || '' }`);

        if(!(page in pages))
            return navigate(`/${ site }/dashboard/statistics${ location.search || '' }`);

        if(loading)
            return;

        if(!user && page !== 'auth')
            return navigate(`/${ site }/auth${ location.search || '' }`);

        if(user && page === 'auth')
            return navigate(`/${ site }/dashboard/statistics${ location.search || '' }`);

        if(!([ 'auth', 'error' ].includes(page)) && !(hasPermissions(user.permissions.all, pages[ page ][ 1 ]))) {
            const page = Object
                .keys(pages)
                .find(page => {
                    return hasPermissions(user.permissions.all, page[ 1 ]);
                });

            return navigate(`/${
                site
            }/${
                page
            }${
                location.search || ''
            }`);
        }
    }, [ error, site, page, user, navigate, loading ]);

    useEffect(() => {
        api.on('connected', ({ user = false, features = false } = {}) => {
            dispatch(setUser(user));
            dispatch(setFeatures(features));

            api.getCurrentItemList().then(items => {
                dispatch(setItemList(items));
            }).catch(error => {
                logger.warn('Failed to fetch item list');
                logger.error(error);

                dispatch(setError('Failed to fetch current item list'));
            });
        });

        api.on('config', config => {
            dispatch(setConfig(config));
        });

        api.on('error', error => {
            dispatch(setError(error));
        });

        api.on('disconnected', () => {
            dispatch(setError('Connection lost or unattainable'));
        });

        api.on('token', refreshToken => {
            localStorage.setItem(`auth.${ site }`, refreshToken);
        });

        return () => {
            api.removeAllListeners();
        };
    }, [ dispatch, site ]);

    useEffect(() => {
        if(!isOnline)
            api.disconnect();
    }, [ isOnline ]);

    useEffect(() => {
        if(!site)
            return;

        document.title = `Admin Panel - ${ sites[ site ].name } [${ sites[ site ].env }]`;

        if(!isOnline)
            return;

        dispatch(setLoading());

        const token = localStorage
            .getItem(`auth.${ site }`);

        api.setHost(sites[ site ].api);
        api.setRefreshToken(token);
        api.authToken = false;

        api.connect();
    }, [ site, isOnline, dispatch ]);

    const CurrentPage = (!loading && !error && isOnline && pages?.[ page ]?.[ 0 ]) || pages.error[ 0 ];

    return (site in sites && page in pages) ? (
        <>
            <div className='page'>
                <div className='sticky-top'>
                    <Header site={ site } />
                    <Navigation site={ site } page={ page } />
                </div>
                <div className='page-wrapper'>
                    { loading ? (
                        <LoadingScreen />
                    ) : <CurrentPage site={ site } /> }
                    <Footer site={ site } />
                </div>
            </div>
            <div id='modals' />
        </>
    ) : '';
};

export default connect(state => ({
    loading: state.app.loading,
    error: state.app.error,
    user: state.app.user
}))(App);