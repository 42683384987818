import React, { memo } from 'react';

const colours = {
    production: 'badge bg-red-lt',
    staging: 'badge bg-orange-lt',
    local: 'badge bg-cyan-lt'
};

const EnvironmentLabel = ({ env }) => {
    const className = colours[ env ];

    return (
        <span className={ className }>{ env }</span>
    );
};

export default memo(EnvironmentLabel);