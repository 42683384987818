import { createReducer } from '@reduxjs/toolkit';
import * as actions from 'store/actions/app';

const DEFAULT_FEATURES = {
    games: {
        coinflip: false,
        roulette: false,
        battles: false,
        double: false,
        plinko: false,
        cases: false,
        crash: false,
        dice: false,
        hub: false
    },
    items: {
        csgo: [],
        rust: []
    },
    crypto: false,
    gg: false
};

export default createReducer({
    features: DEFAULT_FEATURES,
    loading: true,
    config: false,
    error: false,
    user: false
}, {
    [ actions.setUser ]: (state, { payload: user }) => {
        state.loading = false;
        state.error = false;
        state.user = user;
    },

    [ actions.setError ]: (state, { payload: error }) => {
        state.features = DEFAULT_FEATURES;
        state.loading = false;
        state.config = false;
        state.error = error;
    },

    [ actions.setLoading ]: (state, { payload = true }) => {
        state.loading = payload;
        state.error = false;
    },

    [ actions.setFeatures ]: (state, { payload }) => {
        state.features = payload || DEFAULT_FEATURES;
    },

    [ actions.setConfig ]: (state, { payload = false }) => {
        state.config = payload;
        state.config.currencies = [ 'cash', 'crypto' ];
    },

    [ actions.setItemList ]: (state, { payload }) => {
        state.items = payload;
    }
});
