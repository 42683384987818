import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

const SocialHandles = ({ setLoading, getConfig, loading, config, show }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('socialMedia', { defaultValue: JSON.parse(JSON.stringify(config?.socialMedia || {})) });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        for(const key in updatedConfig.socialMedia) {
            if(updatedConfig.socialMedia[ key ] !== config.socialMedia[ key ])
                parameters[ key ] = updatedConfig.socialMedia[ key ];
        }

        setLoading(true);

        api
            .setConfigSocial(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    const socialMedia = watch('socialMedia'); // Again, don't even ask.
    const isDirty = JSON.stringify(config?.socialMedia) !== JSON.stringify(socialMedia);

    return (
        <div className={ !(show) ? 'd-none' : '' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Social handles
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Discord
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                https://discord.gg/invite/
                            </span>
                            <input
                                className={ `form-control ps-0 ${ formState.errors.socialMedia?.discord ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('socialMedia.discord', {
                                    pattern: /^[a-zA-Z0-9-]{2,32}$/
                                }) }
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Facebook
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                https://facebook.com/
                            </span>
                            <input
                                className={ `form-control ps-0 ${ formState.errors.socialMedia?.facebook ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('socialMedia.facebook', {
                                    pattern: /^[a-z\d.]{5,}$/i
                                }) }
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Instagram
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                https://instagram.com/
                            </span>
                            <input
                                className={ `form-control ps-0 ${ formState.errors.socialMedia?.instagram ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('socialMedia.instagram', {
                                    pattern: /^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/
                                }) }
                            />
                        </div>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Twitter
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                https://twitter.com/
                            </span>
                            <input
                                className={ `form-control ps-0 ${ formState.errors.socialMedia?.twitter ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('socialMedia.twitter', {
                                    pattern: /^[a-zA-Z_]{1}[a-zA-Z0-9_]{0,14}$/
                                }) }
                            />
                        </div>
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SocialHandles;