import api from 'api';
import React from 'react';

import { useLayoutEffect, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const Flairs = ({ loading, setLoading, setError, query, setQuery, user, getUser }) => {
    const [ flairs, setFlairs ] = useState([]);

    const {
        handleSubmit,
        resetField,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        if(!(user.flairs))
            return;

        flairs.forEach(flair => {
            resetField(flair, {
                defaultValue: user.flairs.includes(flair)
            });
        });
    }, [ flairs, user.flairs ]);

    useLayoutEffect(() => {
        setLoading(true);

        api.getFlairs().then(flairs => {
            setFlairs(flairs);
            setLoading(false);
        }).catch(error => {
            console.warn('Failed to fetch list of user flairs');
            console.error(error);

            setLoading(false);
            setError(error);
        });
    }, [ ]);

    const onCloseModal = useCallback(() => {
        query.delete('flairs');
        setQuery(query);
    }, [ query ]);

    const onSubmit = flairs => {
        setLoading(true);

        const newFlairs = Object.entries(flairs).reduce((flairs, [ key, value ]) => {
            if(value)
                flairs.push(key);

            return flairs;
        }, []);

        api.setUserFlairs(user.userID, newFlairs)
            .then(() => {
                return getUser();
            })
            .catch(error => {
                console.warn('Failed to set user flairs');
                console.error(error);
                setError(error);
            });
    };

    return flairs.length ? (
        <Modal
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Set flairs for { user.username }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body'>
                <div className='form-selectgroup form-selectgroup-boxes d-flex flex-column'>
                    { flairs.map(flair => (
                        <label className='form-selectgroup-item flex-fill' key={ flair }>
                            <input
                                className='form-selectgroup-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register(flair) }
                            />
                            <div className='form-selectgroup-label d-flex align-items-center p-3'>
                                <div className='me-3'>
                                    <span className='form-selectgroup-check' />
                                </div>
                                <div className='form-selectgroup-label-content'>
                                    <div>
                                        <div className='font-weight-medium'>
                                            { flair }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    )) }
                </div>
            </div>
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (loading || !(formState.isDirty)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Saving<span className='animated-dots' />
                        </span>
                    ) : 'Save changes' }
                </button>
            </div>
        </Modal>
    ) : '';
};

export default Flairs;