import api from 'api';
import React, { useCallback } from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

const General = ({ setLoading, getConfig, loading, config, show }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('staffOnly', { defaultValue: config.staffOnly });
        resetField('analytics', { defaultValue: config.analytics });
        resetField('notice', { defaultValue: config.notice });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        for(const key in updatedConfig) {
            if(updatedConfig[ key ] !== config[ key ])
                parameters[ key ] = updatedConfig[ key ];
        }

        setLoading(true);

        api
            .setConfigGeneral(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    const updateItemList = useCallback(() => {
        setLoading(true);

        api
            .updateItemList()
            .then(() => {
                setLoading(false);
                window.alert('Successfully updated item list for CS:GO and DOTA items'); // eslint-disable-line no-alert
            })
            .catch(error => {
                console.warn('Failed to update item list');
                console.error(error);
                setLoading(false);
            });
    }, []);

    return (
        <div className={ !(show) ? 'd-none' : '' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    General
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Site notice
                    </label>
                    <div className='col'>
                        <input
                            placeholder='Site notice'
                            className={ `form-control ${ formState.errors.notice ? 'is-invalid' : '' }` }
                            disabled={ loading }
                            type='text'
                            { ...register('notice', {
                                minLength: 4,
                                maxLength: 256
                            }) }
                        />
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.notice?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Notice will be displayed as a banner to all users visiting the site
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Maintenance mode
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('staffOnly') }
                            />
                        </label>
                        <small className='form-hint'>
                            Maintenance mode will prevent regular users from accessing the site
                        </small>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Analytics enabled
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('analytics') }
                            />
                        </label>
                        <small className='form-hint'>
                            Enabling analytics will ship all user events to our third party data store
                        </small>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Inventory items
                    </label>
                    <div className='col'>
                        <button
                            className='btn btn-primary btn-sm mb-1'
                            disabled={ loading }
                            onClick={ updateItemList }
                        >
                            Refresh inventory item list
                        </button>
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(formState.isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default General;