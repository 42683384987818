import React from 'react';
import api from 'api';
import DeleteFlair from './modals/DeleteFlair';
import CreateFlair from './modals/CreateFlair';

import { useLayoutEffect, useState } from 'react';
import { useCallback } from 'react';
import { ROLE_COLOURS } from '../Users';
import { IconX } from '@tabler/icons';

const Flairs = ({ site }) => {
    const [ loading, setLoading ] = useState(true);
    const [ flairs, setFlairs ] = useState([]);
    const [ error, setError ] = useState(false);
    const [ modal, setModal ] = useState(false);

    const fetchFlairs = () => {
        return api
            .getFlairs()
            .then(flairs => {
                setFlairs(flairs);
                setLoading(false);
                setError(false);
            })
            .catch(error => {
                console.warn('Failed to fetch list of flairs');
                console.error(error);

                setError(error);
                setLoading(false);
            });
    };

    const onCloseModal = useCallback(() => {
        if(loading)
            return;

        setModal(false);
    }, [ loading ]);

    const onDelete = useCallback(() => {
        setLoading(true);

        api
            .deleteFlair(modal.substr(7))
            .then(() => {
                return fetchFlairs();
            })
            .then(() => {
                setModal(false);
            })
            .catch(error => {
                console.warn('Failed to delete flair');
                console.error(error);

                switch (error) {
                    case 'INVALID_FLAIR': setError('Flair does not exist'); break;
                    case 'NON_DELETABLE_FLAIR': setError('Cannot delete system flairs'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
            });
    }, [ modal ]);

    const onCreate = useCallback(flair => {
        setLoading(true);

        return api
            .createFlair(flair)
            .then(() => {
                return fetchFlairs();
            })
            .then(() => {
                setModal(false);
            })
            .catch(error => {
                console.warn('Failed to create flair');
                console.error(error);

                switch (error) {
                    case 'INVALID_FLAIR': setError('Invalid flair name provided'); break;
                    case 'FLAIR_ALREADY_EXISTS': setError('Provided flair name is already in use'); break;
                    default: setError(`Unknown error: ${ error }`);
                }

                setLoading(false);
            });
    }, [ ]);

    useLayoutEffect(() => {
        fetchFlairs();
    }, [ site ]);

    return (
        <>
            <div className='container-xl'>
                <div className='page-header'>
                    <div className='row g-2 align-items-center'>
                        <div className='col'>
                            <div className='page-pretitle'>
                                Users
                            </div>
                            <h2 className='page-title'>
                                Flairs
                            </h2>
                        </div>
                        <div className='col-12 col-md-auto ms-auto'>
                            <button
                                className='btn btn-primary'
                                disabled={ loading }
                                onClick={ () => setModal('create') }
                            >
                                Create flair
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='page-body roles'>
                <div className='container-xl'>
                    <div className='row row-cards placeholder-glow'>
                        { (loading && !(Object.keys(flairs).length)) ? Array.from({ length: 3 }).map((_, index) => (
                            <div className='col-md-6 col-lg-4' key={ index }>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='card-title'>
                                            <div className='d-flex align-items-center'>
                                                <span className='placeholder placeholder-xs col-6' />
                                                <span className='placeholder placeholder-xs col-1 ms-auto' />
                                            </div>
                                        </div>
                                        <p className='text-muted'>
                                            <span className='placeholder placeholder-xs col-9' />
                                            <span className='placeholder placeholder-xs col-6' />
                                            <span className='placeholder placeholder-xs col-8' />
                                            <span className='placeholder placeholder-xs col-5' />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )) : flairs.map(flair => (
                            <div className='col-md-6 col-lg-4' key={ flair }>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className={ `card-title mb-1 ${ ROLE_COLOURS[ flair ] || '' }` }>
                                            <div className='d-flex align-items-center'>
                                                <span className='role-name'>
                                                    { flair }
                                                </span>
                                                <div className='card-actions btn-actions'>
                                                    { (!(flair in ROLE_COLOURS)) ? (
                                                        <a
                                                            className='btn-action text-red'
                                                            onClick={ () => setModal(`delete-${ flair }`) }
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <IconX />
                                                        </a>
                                                    ) : '' }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
            </div>
            { modal === 'create' ? (
                <CreateFlair
                    onCloseModal={ onCloseModal }
                    onCreate={ onCreate }
                    loading={ loading }
                    flairs={ flairs }
                    error={ error }
                />
            ) : (modal && modal.startsWith('delete')) ? (
                <DeleteFlair
                    onCloseModal={ onCloseModal }
                    onDelete={ onDelete }
                    loading={ loading }
                    flair={ modal.substr(7) }
                    error={ error }
                />
            ) : '' }
        </>
    );
};

export default Flairs;