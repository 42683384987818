import React from 'react';
import api from 'api';
import EnvironmentLabel from 'components/ui/EnvironmentLabel';
import sites from 'sites';

import { connect, useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { setLoading, setUser } from 'store/actions/app';

const Header = ({ site, user }) => {
    const dispatch = useDispatch();
    const siteMeta = sites[ site ];

    const signOut = useCallback(event => {
        event.preventDefault();
        localStorage.removeItem(`auth.${ site }`);

        dispatch(setLoading());
        dispatch(setUser(false));

        api.setRefreshToken(false);
        api.connect();
    }, [ site, dispatch ]);

    return (
        <header className='navbar navbar-expand-md navbar-light sticky-top'>
            <div className='container-xl'>
                <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbar-menu'>
                    <span className='navbar-toggler-icon'></span>
                </button>
                <h1 className='navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3'>
                    <a>
                        <span className='pe-2'>{ siteMeta.name }</span>
                        <EnvironmentLabel env={ siteMeta.env } />
                    </a>
                </h1>
                { user ? (<div className='navbar-nav flex-row order-md-last'>
                    <div className='nav-item dropdown'>
                        <a href='#' className='nav-link d-flex lh-1 text-reset p-0' data-bs-toggle='dropdown'>
                            <span className='avatar avatar-sm' style={{ backgroundImage: `url(${ user.avatar })` }} />
                            <div className='d-none d-xl-block ps-2'>
                                <div>{ user.username }</div>
                                <div className='mt-1 small text-muted'>{ user.permissions.role || 'Member' }</div>
                            </div>
                        </a>
                        <div className='dropdown-menu dropdown-menu-end dropdown-menu-arrow'>
                            {/* <a className='dropdown-item'>Profile</a>
                            <a className='dropdown-item'>Something else</a>
                            <div className='dropdown-divider'></div> */}
                            <a href='#' className='dropdown-item' onClick={ signOut }>Logout</a>
                        </div>
                    </div>
                </div>) : '' }
            </div>
        </header>
    );
};

export default connect(state => ({
    user: state.app.user
}))(Header);