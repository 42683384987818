import React from 'react';

import { memo, useLayoutEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IconCheck } from '@tabler/icons';
import { hasPermissions, Permissions } from 'utils/permissions';

const findParentRoles = (roles, child, checked = []) => {
    const parents = Object
        .values(roles)
        .filter(({ inherits }) => inherits === child)
        .map(({ name }) => name);

    for(const role of parents) {
        if(checked.includes(role))
            continue;

        parents.push(...findParentRoles(roles, role, checked));
        checked.push(role);
    }

    return parents;
};

const EditRole = ({ loading, roles, role, error, onCloseModal, onEdit, prettifyPermission, traversePermissions }) => {
    const [ inheritedPermissions, setInheritedPermissions ] = useState(0);
    const [ updated, setUpdated ] = useState(false);

    const {
        handleSubmit,
        resetField,
        formState,
        getValues,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const parentRoles = findParentRoles(roles, role);
    const inherits = watch('inherits', roles[ role ].inherits);

    useLayoutEffect(() => {
        resetField('inherits', { defaultValue: roles[ role ].inherits });

        Object
            .entries(Permissions)
            .forEach(([ name, permission ]) => {
                const hasPermission = !!hasPermissions(roles[ role ].individual, permission);

                resetField(`permission.${ name }`, {
                    defaultValue: hasPermission
                });
            });
    }, [ roles ]);

    useLayoutEffect(() => {
        const inheritedPermissions = traversePermissions(roles, inherits !== 'false' && inherits);

        setInheritedPermissions(inheritedPermissions);
    }, [ roles, inherits ]);

    const onSubmit = async () => {
        const { inherits, permission } = getValues();

        const permissions = Object
            .entries(permission)
            .reduce((permissions, [ name, hasPermission ]) => {
                if(!(hasPermission))
                    return permissions;

                return permissions | Permissions[ name ];
            }, 0);

        setUpdated(false);

        const updated = await onEdit({
            inherits: inherits !== 'false' && inherits,
            permissions
        });

        if(updated)
            setUpdated(true);
    };

    return (
        <Modal
            dialogClassName='modal-lg edit-role-modal modal-dialog-scrollable'
            animation={ false }
            onHide={ onCloseModal }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Edit { role }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body inherits'>
                <label className='form-label'>
                    Inherits
                </label>
                <select
                    className='form-select'
                    disabled={ loading || parentRoles.length === (Object.keys(roles).length - 1) }
                    { ...register('inherits') }
                >
                    { Object.keys(roles).map(name => (
                        <option
                            disabled={ role === name || parentRoles.includes(name) }
                            value={ name }
                            key={ name }
                        >
                            { name }
                        </option>
                    )) }
                    <option value='false'>
                        No inheritance
                    </option>
                </select>
            </div>
            <div className='modal-body permissions'>
                <div className='row row-cards'>
                    { Object.entries(Permissions).map(([ name, permission ]) => (
                        <div className='col-md-6' key={ name }>
                            <button
                                className={ `btn w-100 d-flex align-items-center permission ${ hasPermissions(inheritedPermissions, permission) ? 'selected disabled' : '' }` }
                                disabled={ loading }
                                type='button'
                            >
                                <input
                                    className='form-selectgroup-input'
                                    type='checkbox'
                                    { ...register(`permission.${ name }`) }
                                />
                                <span>
                                    { prettifyPermission(name) }
                                </span>
                                <div className='ms-auto btn btn-icon disabled bg-green' >
                                    <IconCheck />
                                </div>
                            </button>
                        </div>
                    )) }
                </div>
                { error ? (
                    <div className='card bg-red-lt mt-3'>
                        <div className='card-body'>
                            <h3 className='card-title mb-0'>
                                Failed to save role
                            </h3>
                            <p className='text-muted'>
                                { error }
                            </p>
                        </div>
                    </div>
                ) : '' }
            </div>
            { (error || updated) ? (
                <div className='modal-body message'>
                    { error ? (
                        <div className='card bg-red-lt text-center'>
                            <div className='card-body'>
                                <h3 className='card-title mb-0'>
                                    Failed to save role
                                </h3>
                                <p className='text-muted'>
                                    { error }
                                </p>
                            </div>
                        </div>
                    ) : updated ? (
                        <div className='card bg-green-lt text-center'>
                            <div className='card-body'>
                                <h3 className='card-title mb-0'>
                                    Saved role
                                </h3>
                                <p className='text-muted'>
                                    Successfully updated permissions for role <strong>{ role }</strong>
                                </p>
                            </div>
                        </div>
                    ) : '' }
                </div>
            ) : '' }
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (loading || !formState.isDirty) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Saving<span className='animated-dots' />
                        </span>
                    ) : 'Save changes' }
                </button>
            </div>
        </Modal>
    );
};

export default memo(EditRole);