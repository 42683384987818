import React, { memo } from 'react';
import Modal from 'components/ui/Modal';
import sites from 'sites';
import EnvironmentLabel from 'components/ui/EnvironmentLabel';
import pages from 'components/pages';

import { IconAd, IconBox, IconBrandAppleArcade, IconBriefcase, IconBusinessplan, IconCash, IconChartAreaLine, IconChartCircles, IconCircles, IconDeviceGamepad2, IconDice5, IconGridDots, IconLayoutDashboard, IconReport, IconReportMoney, IconSettings, IconSwitch3, IconSword, IconTrendingDown, IconUser, IconUserCheck, IconUserExclamation, IconUsers } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasPermissions } from 'utils/permissions';

const Navigation = ({ site, page, user, loading, error, features }) => {
    const loadingClass = (loading || error || page === 'auth') ? 'disabled' : '';

    const authClass = pageName => {
        const hasPermission = Object
            .keys(pages)
            .filter(page => page.startsWith(pageName))
            .some(page => {
                return hasPermissions(user?.permissions?.all || 0, pages[ page ][ 1 ]);
            });

        return hasPermission ? '' : 'disabled';
    };

    const dropdownProps = pageName => {
        return {
            className: `nav-item dropdown ${ page.startsWith(pageName) ? 'active' : '' }`,
        };
    };

    const dropdownItemProps = pageName => {
        const featureClass = pageName.startsWith('games/') ?
            features.games[ pageName.substr(6) ] ? '' : 'd-none' :
            '';

        const authClass = hasPermissions(user?.permissions?.all || 0, pages[ pageName ][ 1 ]) ?
            '' :
            'd-none';

        const activeClass = pageName === page ?
            'active' :
            '';

        return {
            className: `dropdown-item ${ activeClass } ${ featureClass } ${ loadingClass } ${ authClass }`,
            to: `/${ site }/${ pageName }`
        };
    };

    const linkProps = pageName => {
        return {
            className: `nav-item ${ pageName === page ? 'active' : '' }`
        };
    };

    const listOfSites = Object
        .entries(sites)
        .map(([ siteID, { name, env }] ) => {
            const props = {
                'aria-current': siteID === site,
                className: `list-group-item list-group-item-action ${ siteID === site ? 'active disabled' : '' }`
            };

            return (
                <span key={ siteID } data-bs-dismiss='modal'>
                    <Link to={ `/${ siteID }/${ page }`} { ...props }>
                        { name }<EnvironmentLabel env={ env } />
                    </Link>
                </span>
            );
        });

    return (
        <div className='navbar-expand-md'>
            <div className='collapse navbar-collapse' id='navbar-menu'>
                <div className='navbar navbar-light'>
                    <div className='container-xl'>
                        <ul className='navbar-nav'>
                            <li { ...dropdownProps('dashboard')}>
                                <div className={ `nav-link dropdown-toggle ${ loadingClass } ${ authClass('dashboard') }`} data-bs-toggle='dropdown' role='button' aria-expanded='false'>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconLayoutDashboard />
                                    </span>
                                    <span className='nav-link-title'>
                                        Dashboard
                                    </span>
                                </div>
                                <div className='dropdown-menu'>
                                    <Link { ...dropdownItemProps('dashboard/statistics') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconChartAreaLine />
                                        </span>
                                        <span className='nav-link-title'>
                                            Statistics
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('dashboard/transactions') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconReportMoney />
                                        </span>
                                        <span className='nav-link-title'>
                                            Transactions
                                        </span>
                                    </Link>
                                </div>
                            </li>
                            <li { ...dropdownProps('games') }>
                                <div className={ `nav-link dropdown-toggle ${ loadingClass } ${ authClass('games') }`} data-bs-toggle='dropdown' role='button' aria-expanded='false'>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconDeviceGamepad2 />
                                    </span>
                                    <span className='nav-link-title'>
                                        Games
                                    </span>
                                </div>
                                <div className='dropdown-menu'>
                                    <Link { ...dropdownItemProps('games/battles') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconSword />
                                        </span>
                                        <span className='nav-link-title'>
                                            Battles
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/cases') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconBriefcase />
                                        </span>
                                        <span className='nav-link-title'>
                                            Case Opening
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/coinflip') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconChartCircles />
                                        </span>
                                        <span className='nav-link-title'>
                                            Coinflip
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/crash') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconTrendingDown />
                                        </span>
                                        <span className='nav-link-title'>
                                            Crash
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/dice') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconDice5 />
                                        </span>
                                        <span className='nav-link-title'>
                                            Dice
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/double') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconCircles />
                                        </span>
                                        <span className='nav-link-title'>
                                            Double
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/hub') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconBrandAppleArcade />
                                        </span>
                                        <span className='nav-link-title'>
                                            Hub
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/plinko') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconGridDots />
                                        </span>
                                        <span className='nav-link-title'>
                                            Plinko
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('games/roulette') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconBusinessplan />
                                        </span>
                                        <span className='nav-link-title'>
                                            Roulette
                                        </span>
                                    </Link>
                                </div>
                            </li>
                            <li { ...linkProps('case-creator') }>
                                <Link to={ `/${ site }/case-creator` } className={ `nav-link ${ loadingClass } ${ authClass('case-creator') }`}>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconBox />
                                    </span>
                                    <span className='nav-link-title'>
                                        Case creator
                                    </span>
                                </Link>
                            </li>
                            <li { ...dropdownProps('users') }>
                                <div className={ `nav-link dropdown-toggle ${ loadingClass } ${ authClass('users') }`} data-bs-toggle='dropdown' role='button' aria-expanded='false'>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconUsers />
                                    </span>
                                    <span className='nav-link-title'>
                                        Users
                                    </span>
                                </div>
                                <div className='dropdown-menu'>
                                    <Link { ...dropdownItemProps('users') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconUser />
                                        </span>
                                        <span className='nav-link-title'>
                                            Site members
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('users/roles') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconUserCheck />
                                        </span>
                                        <span className='nav-link-title'>
                                            Roles
                                        </span>
                                    </Link>
                                    <Link { ...dropdownItemProps('users/flairs') }>
                                        <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                            <IconUserExclamation />
                                        </span>
                                        <span className='nav-link-title'>
                                            Flairs
                                        </span>
                                    </Link>
                                </div>
                            </li>
                            <li { ...linkProps('affiliates') }>
                                <Link to={ `/${ site }/affiliates` } className={ `nav-link ${ loadingClass } ${ authClass('affiliates') }`}>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconAd />
                                    </span>
                                    <span className='nav-link-title'>
                                        Affiliates
                                    </span>
                                </Link>
                            </li>
                            <li { ...linkProps('promo-codes') }>
                                <Link to={ `/${ site }/promo-codes` } className={ `nav-link ${ loadingClass } ${ authClass('promo-codes') }`}>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconCash />
                                    </span>
                                    <span className='nav-link-title'>
                                        Promo codes
                                    </span>
                                </Link>
                            </li>
                            <li { ...linkProps('settings') }>
                                <Link to={ `/${ site }/settings` } className={ `nav-link ${ loadingClass } ${ authClass('settings') }`}>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconSettings />
                                    </span>
                                    <span className='nav-link-title'>
                                        Site configuration
                                    </span>
                                </Link>
                            </li>
                            <li { ...linkProps('logs') }>
                                <Link to={ `/${ site }/logs` } className={ `nav-link ${ loadingClass } ${ authClass('logs') }`}>
                                    <span className='nav-link-icon d-md-none d-lg-inline-block'>
                                        <IconReport />
                                    </span>
                                    <span className='nav-link-title'>
                                        System logs
                                    </span>
                                </Link>
                            </li>
                        </ul>
                        <div className='my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-last'>
                            <button type='button' className={ `btn btn-light ${ loading ? 'disabled' : '' }` } data-bs-toggle='modal' data-bs-target='#modal-switch-sites'>
                                <IconSwitch3 />
                                Switch site
                            </button>
                            <Modal name='switch-sites'>
                                <div className='modal-header'>
                                    <h5 className='modal-title'>Switch site</h5>
                                    <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                                </div>
                                <div className='list-group list-group-flush'>
                                    { listOfSites }
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    features: state.app.features,
    loading: state.app.loading,
    error: state.app.error,
    user: state.app.user
}))(memo(Navigation));