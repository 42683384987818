import React from 'react';

import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const CreateFlair = ({ loading, flairs, error, onCloseModal, onCreate }) => {
    const {
        handleSubmit,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    const flair = watch('flair', '');

    const onSubmit = useCallback(() => {
        onCreate(flair.toLowerCase());
    }, [ flair ]);

    return (
        <Modal
            dialogClassName='edit-role-modal modal-dialog-scrollable'
            animation={ false }
            onHide={ onCloseModal }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Create flair
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body name'>
                <label className='form-label'>
                    Name
                </label>
                <input
                    placeholder='Role name'
                    className={ `form-control ${ flair && flair.length && formState.errors.role ? 'is-invalid' : '' }` }
                    disabled={ loading }
                    type='text'
                    { ...register('flair', {
                        validate: flair => !(flairs.includes(flair)),
                        required: true,
                        pattern: /^[a-zA-Z]{4,18}$/
                    }) }
                />
            </div>
            { error ? (
                <div className='modal-body message'>
                    <div className='card bg-red-lt text-center'>
                        <div className='card-body'>
                            <h3 className='card-title mb-0'>
                                Failed to create flair
                            </h3>
                            <p className='text-muted'>
                                { error }
                            </p>
                        </div>
                    </div>
                </div>
            ) : '' }
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (!(formState.isValid) || loading || !(formState.isDirty)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Creating<span className='animated-dots' />
                        </span>
                    ) : 'Create flair' }
                </button>
            </div>
        </Modal>
    );
};

export default CreateFlair;