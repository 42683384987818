import React from 'react';
import api from 'api';
import General from './panels/General';
import Transactions from './panels/Transactions';
import Captchas from './panels/Captchas';
import Authentication from './panels/Authentication';
import Affiliates from './panels/Affiliates';
import SocialHandles from './panels/SocialHandles';
import Requirements from './panels/Requirements';
import Rewards from './panels/Rewards';

import { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import './SiteConfiguration.scss';

const panels = {
    general: General,
    transactions: Transactions,
    captchas: Captchas,
    authentication: Authentication,
    affiliates: Affiliates,
    social: SocialHandles,
    requirements: Requirements,
    rewards: Rewards
};

const SiteConfiguration = ({ site }) => {
    const [ loading, setLoading ] = useState(true);
    const [ config, setConfig ] = useState(false);
    const [ query, setQuery ] = useSearchParams();
    const [ error, setError ] = useState(false);
    const [ page, setPage ] = useState(false);

    useEffect(() => {
        const page = query.get('page');

        if(!(page))
            return setPage('general');

        if(!(page in panels)) {
            query.delete('page');
            return setQuery(query);
        }

        setPage(page);
    }, [ query, setQuery ]);

    const getConfig = useCallback(() => {
        api
            .getConfig()
            .then(config => {
                setLoading(false);
                setConfig(config);
                setError(false);
            })
            .catch(error => {
                console.warn('Failed to fetch site config');
                console.error(error);

                setLoading(false);
                setError(error);
            });
    }, []);

    useLayoutEffect(() => {
        getConfig();
    }, [ site ]);

    // Transactions
    //   For each method, deposits / withdraws enabled per row
    //   waxpeerFee - Our own markup on waxpeer listings
    //   peerWithdrawFilter - ?? Possibly minimum $ value of items to show for peer withdraw

    return (
        <>
            <div className='container-xl'>
                <div className='page-header'>
                    <div className='row g-2 align-items-center'>
                        <div className='col'>
                            <h2 className='page-title'>
                                Site configuration
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='page-body site-configuration'>
                <div className='container-xl'>
                    <div className='row'>
                        <div className='col-12 col-md-4 px-4'>
                            <div className='list-group list-group-transparent mb-3 ml-3'>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'general' ? 'active' : '' }` }
                                    to={ `/${ site }/settings` }
                                >
                                    General
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'transactions' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=transactions` }
                                >
                                    Transactions
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'captchas' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=captchas` }
                                >
                                    Captchas
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'authentication' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=authentication` }
                                >
                                    Authentication
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'affiliates' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=affiliates` }
                                >
                                    Affiliates
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'rewards' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=rewards` }
                                >
                                    Rewards
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'requirements' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=requirements` }
                                >
                                    Requirements and limits
                                </Link>
                                <Link
                                    className={ `list-group-item list-group-item-action ${ page === 'social' ? 'active' : '' }` }
                                    to={ `/${ site }/settings?page=social` }
                                >
                                    Social handles
                                </Link>
                            </div>
                        </div>
                        <div className='col-12 col-md-8'>
                            <div className='row'>
                                <div className='col-12'>
                                    { error ? (
                                        <div className='card mb-3'>
                                            <div className='card-status-top bg-danger' />
                                            <div className='card-body'>
                                                <h3 className='card-title mb-1'>
                                                    Error occurred
                                                </h3>
                                                <p className='text-muted'>
                                                    Sorry, an error occurred: { error }
                                                </p>
                                            </div>
                                        </div>
                                    ) : '' }
                                    <div className={ `card ${ error ? 'd-none' : '' }`}>
                                        { Object.entries(panels).map(([ name, Panel ]) => (
                                            <Panel
                                                setLoading={ setLoading }
                                                getConfig={ getConfig }
                                                loading={ loading }
                                                config={ config }
                                                show={ name === page }
                                                key={ name }
                                            />
                                        )) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SiteConfiguration;