export const Permissions = {
    // Misc
    CAN_BYPASS_TIP_LIMIT: Math.pow(2, 0),
    CAN_BYPASS_CHAT_LIMIT: Math.pow(2, 1),
    CAN_BYPASS_SLOW_MODE: Math.pow(2, 2),
    CAN_BYPASS_STAFF_MODE: Math.pow(2, 3),
    CAN_CREATE_UNLIMITED_CHAT_RAIN: Math.pow(2, 4),
    HAS_SPONSOR_LOCK: Math.pow(2, 5),
    CREATE_CASE: Math.pow(2, 26),

    // Helper permissions
    CAN_MUTE_USERS: Math.pow(2, 8),
    CAN_DELETE_CHAT_MESSAGES: Math.pow(2, 9),
    CAN_MODIFY_CHAT: Math.pow(2, 10),

    // Moderator permissions
    CAN_BAN_USER: Math.pow(2, 11),
    CAN_VIEW_USER: Math.pow(2, 12),
    CAN_MODIFY_USER: Math.pow(2, 13),
    CAN_VIEW_AFFILIATE: Math.pow(2, 14),

    // Admin permissions
    CAN_VIEW_ADMIN_PANEL: Math.pow(2, 15),
    CAN_VIEW_ADMIN_LOGS: Math.pow(2, 16),
    CAN_MODIFY_ROLE: Math.pow(2, 17),
    CAN_MODIFY_AFFILIATE: Math.pow(2, 18),
    CAN_MODIFY_CONFIG: Math.pow(2, 19),
    CAN_VIEW_STATISTICS: Math.pow(2, 20),
    CAN_VIEW_PROMO_CODE: Math.pow(2, 21),
    CAN_MODIFY_PROMO_CODE: Math.pow(2, 22),
    CAN_VIEW_TRANSACTIONS: Math.pow(2, 23),
    CAN_MODIFY_FLAIR: Math.pow(2, 24)
};

export const hasPermissions = (permissions, permission) => {
    return permissions & permission;
};

export const prettifyPermission = key => {
    const text = key
        .toLowerCase()
        .replace(/_/g, ' ');

    return text
        .substr(0, 1)
        .toUpperCase() + text.substr(1);
};

export const traversePermissions = (roles, inheritedRole, checkPermissionsKey = false) => {
    let inherits = inheritedRole;
    let permissions = 0;

    while(inherits) {
        const role = roles[ inherits ];

        permissions |= role[ checkPermissionsKey ? 'permissions' : 'individual' ];
        inherits = role.inherits;
    }

    return permissions;
};