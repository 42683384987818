import React from 'react';

import { IconAlertTriangle } from '@tabler/icons';
import { connect } from 'react-redux';
import { useNetworkConnection } from 'use-network-connection';

const Error = ({ error }) => {
    const { isOnline } = useNetworkConnection();

    return (
        <div className='modal' style={{ position: 'relative', display: 'flex' }}>
            <div className='modal-dialog modal-sm' role='document'>
                <div className='modal-content card'>
                    <div className='modal-status bg-danger'></div>
                    <div className='modal-body text-center'>
                        <IconAlertTriangle className='icon mb-2 text-danger icon-lg' />
                        <div className='text-muted'>
                            Sorry, something went wrong when trying to access the site. Please check your internet connection and try again later.
                        </div>
                        <div className='language-plaintext highlighter-rouge'>
                            <pre className='highlight table-light mt-3 mb-0' style={{ background: '#f8fafc' }}>
                                <code>
                                    { isOnline ? error : 'Your device is not connected to the internet' }
                                </code>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    error: state.app.error
}))(Error);