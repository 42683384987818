export const popupWindow = (url, windowName, w, h) => {
    const y = (window.top.outerHeight / 2) + window.top.screenY - ( h / 2);
    const x = (window.top.outerWidth / 2) + window.top.screenX - ( w / 2);
    return window.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${ w }, height=${ h }, top=${ y }, left=${ x }`);
};

export const decodeJwt = token => {
    const payload = token.split('.')[ 1 ]
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const encoded = decodeURIComponent(window.atob(payload).split('').map(char => {
        return `%${ (`00${ char.charCodeAt(0).toString(16) }`).slice(-2) }`;
    }).join(''));

    return JSON.parse(encoded);
};

export const request = async ({
    method = 'GET',
    token,
    data,
    url,
}) => {
    if(![ 'GET', 'POST'].includes(method))
        throw new Error('Invalid method provided, must be one of: GET, POST');

    const headers = {
        ...(token && {
            Authorization: `Bearer ${ token }`
        }),
        ...(method === 'POST' && typeof data !== 'undefined' && {
            'Content-Type': 'application/json'
        }),
    };

    return fetch(url, {
        ...(method === 'POST' && typeof data !== 'undefined' && {
            body: JSON.stringify(data)
        }),
        ...(method === 'GET' && typeof data !== 'undefined' && {
            body: new URLSearchParams(data)
        }),
        headers,
        method
    }).then(async response => {
        const isJson = response.headers
            .get('content-type')
            ?.includes('application/json');

        const data = isJson ?
            response.json() :
            response.text();

        if(response.ok)
            return await data;

        throw {
            statusText: response.statusText,
            status: response.status,
            type: 'request',
            data: await data
        };
    }).catch(error => {
        if(error?.type === 'request')
            throw error;

        throw {
            type: 'network',
            message: error.message
        };
    });
};