import React from 'react';
import api from 'api';
import formatDate from 'dateformat';
import moment from 'moment';
import Flairs from './Flairs';
import PermissionsModal from './Permissions';
import Limits from './Limits';
import Credit from './Credit';
import Ban from './Ban';
import Unban from './Unban';

import { memo, useLayoutEffect, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { IconAd, IconAlertTriangle, IconBrandSteam, IconCurrencyDollar } from '@tabler/icons';
import { hasPermissions, Permissions } from 'utils/permissions';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const renderIdentity = (type, identity) => {
    switch (type) {
        case 'steam': {
            return (
                <a key={ type } href={ `https://steamcommunity.com/profiles/${ identity }` } target='_blank' rel='noreferrer'>
                    <IconBrandSteam />
                </a>
            );
        }
    }
};

const User = ({ userID, onCloseModal, permissions, query, setQuery }) => {
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ user, setUser ] = useState(false);

    const getUser = useCallback(() => {
        api.getUser(userID).then(user => {
            setLoading(false);
            setUser(user);
        }).catch(error => {
            console.warn(`Failed to fetch user ${ userID }`);
            console.error(error);

            setLoading(false);
            setError(error);
        });
    }, [ userID ]);

    useLayoutEffect(() => {
        getUser();
    }, [ userID ]);

    return error ? (
        <Modal
            dialogClassName='modal-sm'
            onHide={ () => !(loading) && setError(false) }
            show={ true }
            centered
        >
            <div className='modal-content'>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ () => setError(false) }
                        type='button'
                    />
                ) : '' }
                <div className='modal-status bg-danger' />
                <div className='modal-body text-center py-4 pb-3'>
                    <IconAlertTriangle className='icon mb-2 text-danger icon-lg' />
                    <h3>
                        Error occurred
                    </h3>
                    <div className='text-muted'>
                        Sorry, an error occurred: <code>{ error }</code>
                    </div>
                </div>
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            <div className='col'>
                                <button className='btn btn-danger w-100' disabled={ loading } onClick={ () => setError(false) }>
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    ) : query.has('flairs') ? (
        <Flairs
            setLoading={ setLoading }
            setError={ setError }
            setQuery={ setQuery }
            loading={ loading }
            getUser={ getUser }
            query={ query }
            user={ user }
        />
    ) : query.has('permissions') ? (
        <PermissionsModal
            setLoading={ setLoading }
            setError={ setError }
            setQuery={ setQuery }
            loading={ loading }
            getUser={ getUser }
            query={ query }
            user={ user }
        />
    ) : query.has('limits') ? (
        <Limits
            setLoading={ setLoading }
            setError={ setError }
            setQuery={ setQuery }
            loading={ loading }
            getUser={ getUser }
            query={ query }
            user={ user }
        />
    ) : query.has('credit') ? (
        <Credit
            setLoading={ setLoading }
            setError={ setError }
            setQuery={ setQuery }
            loading={ loading }
            getUser={ getUser }
            query={ query }
            user={ user }
        />
    ) : query.has('ban') ? (
        <Ban
            setLoading={ setLoading }
            setError={ setError }
            setQuery={ setQuery }
            loading={ loading }
            getUser={ getUser }
            query={ query }
            user={ user }
        />
    ) : query.has('unban') ? (
        <Unban
            setLoading={ setLoading }
            setError={ setError }
            setQuery={ setQuery }
            loading={ loading }
            getUser={ getUser }
            query={ query }
            user={ user }
        />
    ) : user ? (
        <Modal
            dialogClassName='modal-lg user-modal modal-dialog-scrollable'
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ !(error) }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Viewing { user.username }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body no-scroll'>
                <div className='row align-items-center'>
                    <div className='col-auto'>
                        <span
                            className='avatar avatar-lg'
                            style={{
                                backgroundImage: `url(${ user[ 'avatar:image' ] })`
                            }}
                        />
                    </div>
                    <div className='col'>
                        <h4 className='card-title m-0'>
                            { user.username } <span className='badge badge-sm bg-primary-lt'>level { user.xp.level }</span>
                        </h4>
                        <div className='text-muted'>
                            { user[ 'permissions:role' ] || 'member' }
                            { user.flairs.map(flair => (
                                <span className='badge badge-sm bg-secondary-lt ms-1' key={ flair }>
                                    { flair }
                                </span>
                            ))}
                        </div>
                        { Object.entries(user.identities).map(( [ type, { identityID } ]) => (
                            renderIdentity(type, identityID)
                        )) }
                    </div>
                    <div className='col-auto' style={{ textAlign: 'right' }}>
                        <small className='text-muted'>
                            Registered<br/>
                            { formatDate(user.createdAt, 'mmmm dd, yyyy') }
                        </small>
                    </div>
                </div>
            </div>
            <div className='modal-body no-scroll'>
                { user[ 'ban:expiration' ] ? (
                    <div className='card bg-danger-lt mb-3'>
                        <div className='card-body'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <h3 className='card-title mb-0'>
                                        User is { user[ 'ban:expiration' ] > 0 ? 'temporarily' : 'permanently' } banned
                                    </h3>
                                    <p className='text-muted mb-0'>
                                        Reason given: <code>{ user[ 'ban:reason' ] || 'No reason given' }</code>
                                    </p>
                                    { user[ 'ban:expiration' ] > 0 ? (
                                        <small className='text-muted'>
                                            Expires { moment(user[ 'ban:expiration' ]).fromNow() }
                                        </small>
                                    ) : '' }
                                </div>
                                <div className='col-auto'>
                                    <Link
                                        className={ `btn btn-danger ${ (loading || !(hasPermissions(permissions, Permissions.CAN_BAN_USER))) ? 'disabled' : '' }` }
                                        to={ `?user=${ userID }&unban` }
                                    >
                                        Revoke ban
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : '' }
                { user.muted ? (
                    <div className='card bg-warning-lt mb-3'>
                        <div className='card-body'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <h3 className='card-title mb-0'>
                                        User is { user.muted.expiration ? 'temporarily' : 'permanently' } muted
                                    </h3>
                                    <p className='text-muted mb-0'>
                                        Reason given: <code>{ user.muted.reason || 'No reason given' }</code>
                                    </p>
                                    { user.muted.expiration ? (
                                        <small className='text-muted'>
                                            Expires { moment(user.muted.expiration).fromNow() }
                                        </small>
                                    ) : '' }
                                </div>
                            </div>
                        </div>
                    </div>
                ) : '' }
                <div className='row row-cards'>
                    { Object.entries(user.balances).map(( [ currency, balance ]) => (
                        <div className='col' key={ currency }>
                            <div className='card'>
                                <div className='card-body text-center'>
                                    <div className='font-weight-medium'>
                                        ${ Number(balance / 100).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        }) }
                                    </div>
                                    <div className='text-muted'>
                                        { currency }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) }
                </div>
                <div className='row row-cards mt-0'>
                    <div className='col-md-6'>
                        <div className='card card-sm'>
                            <div className='card-body'>
                                <div className='row align-items-center'>
                                    <div className='col-auto'>
                                        <span className='bg-success-lt text-white avatar'>
                                            <IconAd />
                                        </span>
                                    </div>
                                    <div className='col'>
                                        <div>
                                            { user.affiliate ? (
                                                <span>
                                                    Redeemed <strong>{ user.affiliate }</strong>
                                                </span>
                                            ) : 'Hasn\'t redeemed affiliate'}
                                        </div>
                                        <div>
                                            { user.ownsAffiliate ? (
                                                <span>
                                                    Owns <strong>{ user.ownsAffiliate }</strong>
                                                </span>
                                            ) : 'Doesn\'t own affiliate' }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='card card-sm'>
                            <div className='card-body'>
                                <div className='row align-items-center'>
                                    <div className='col-auto'>
                                        <span className='bg-danger-lt text-white avatar'>
                                            <IconCurrencyDollar />
                                        </span>
                                    </div>
                                    <div className='col'>
                                        <div>
                                            Withdraw requirement is <strong>${ (user[ 'withdraw:requirement' ] / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) }</strong>
                                        </div>
                                        <div>
                                            Daily limit is <strong>${ (user[ 'withdraw:limit' ] / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }) }</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card mt-3'>
                    <div className='card-body'>
                        <div className='mb-3 font-weight-bold'>
                            Associated accounts
                        </div>
                        <div className='row g-3'>
                            { Object.entries(user.otherAccounts).map(([ userID, user ]) => (
                                <div className='col-6' key={ userID }>
                                    <div className='row g-3 align-items-center'>
                                        <Link to={ `?user=${ userID }`} className='col-auto'>
                                            <span className='avatar' style={{ backgroundImage: `url(${ user.avatar })` }} />
                                        </Link>
                                        <div className='col text-truncate'>
                                            <Link to={ `?user=${ userID }`} className='text-reset d-block text-truncate'>
                                                { user.username }
                                            </Link>
                                            <div className='text-muted text-truncate mt-n1'>
                                                { user.role || 'member' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
                { !Object.keys(user.otherAccounts).length ? (
                    <div className='card mt-3'>
                        <table className='table card-table'>
                            <thead>
                                <tr>
                                    <th>
                                        Associated accounts
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { Object.entries(user.otherAccounts).map(([ userID, username ]) => (
                                    <tr key={ userID }>
                                        <td>
                                            <Link to={ `?user=${ userID }` }>
                                                <div className='row'>
                                                    <div className='col'>
                                                        { username }
                                                    </div>
                                                    <div className='col-auto'>
                                                        { userID }
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : '' }
                <div className='card mt-3'>
                    <ul className='nav nav-tabs' data-bs-toggle='tabs' role='tablist'>
                        <li className='nav-item' role='presentation'>
                            <a href='#tabs-statistics-transactions' className='nav-link active' data-bs-toggle='tab' aria-selected='true' role='tab'>
                                Transactions
                            </a>
                        </li>
                        { Object.keys(user.statistics.games).map(game => (
                            <li className='nav-item' role='presentation' key={ game }>
                                <a
                                    href={ `#tabs-statistics-${ game }` }
                                    className='nav-link'
                                    data-bs-toggle='tab'
                                    aria-selected='false'
                                    tabIndex='-1'
                                    role='tab'
                                >
                                    { game }
                                </a>
                            </li>
                        )) }
                    </ul>
                    <div className='card-body'>
                        <div className='tab-content'>
                            <div className='tab-pane active show' id='tabs-statistics-transactions' role='tabpanel'>
                                <div className='row row-cards'>
                                    { Object.entries(user.statistics.transactions).map(( [ key, { total } ]) => (
                                        <div className='col' key={ key }>
                                            <div className='card'>
                                                <div className='card-body text-center'>
                                                    <div className='font-weight-medium'>
                                                        ${ Number(total / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }) }
                                                    </div>
                                                    <div className='text-muted'>
                                                        { key }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) }
                                </div>
                            </div>
                            { Object.entries(user.statistics.games).map(( [ game, stats ]) => (
                                <div className='tab-pane' id={ `tabs-statistics-${ game }` } role='tabpanel' key={ game }>
                                    <div className='row row-cards'>
                                        <div className='col'>
                                            <div className='card'>
                                                <div className='card-body text-center'>
                                                    <div className='font-weight-medium'>
                                                        ${ Number(stats.wagered.total / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }) }
                                                    </div>
                                                    <div className='text-muted'>
                                                        wagered
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='card'>
                                                <div className='card-body text-center'>
                                                    <div className='font-weight-medium'>
                                                        ${ Number(stats.profit.total / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }) }
                                                    </div>
                                                    <div className='text-muted'>
                                                        profit
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='card'>
                                                <div className='card-body text-center'>
                                                    <div className='font-weight-medium'>
                                                        { Number(stats.wagers.total).toLocaleString() }
                                                    </div>
                                                    <div className='text-muted'>
                                                        wagers
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='card'>
                                                <div className='card-body text-center'>
                                                    <div className='font-weight-medium'>
                                                        { Number(stats.wins.total).toLocaleString() }
                                                    </div>
                                                    <div className='text-muted'>
                                                        wins
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>
                </div>
            </div>
            { (hasPermissions(permissions, Permissions.CAN_MODIFY_USER) || hasPermissions(permissions, Permissions.CAN_BAN_USER)) ? (
                <div className='modal-footer'>
                    <div className='w-100'>
                        <div className='row'>
                            { hasPermissions(permissions, Permissions.CAN_MODIFY_USER) ? (
                                <>
                                    <div className='col'>
                                        <Link
                                            className={ `btn btn-secondary w-100 ${ loading ? 'disabled' : '' }` }
                                            to={ `?user=${ userID }&flairs` }
                                        >
                                            Flairs
                                        </Link>
                                    </div>
                                    <div className='col'>
                                        <Link
                                            className={ `btn btn-azure w-100 ${ loading ? 'disabled' : '' }` }
                                            to={ `?user=${ userID }&permissions` }
                                        >
                                            Permissions
                                        </Link>
                                    </div>
                                    <div className='col'>
                                        <Link
                                            className={ `btn btn-warning w-100 ${ loading ? 'disabled' : '' }` }
                                            to={ `?user=${ userID }&limits` }
                                        >
                                            Limits
                                        </Link>
                                    </div>
                                    <div className='col'>
                                        <Link
                                            className={ `btn btn-success w-100 ${ loading ? 'disabled' : '' }` }
                                            to={ `?user=${ userID }&credit` }
                                        >
                                            Credit
                                        </Link>
                                    </div>
                                </>
                            ) : '' }
                            { hasPermissions(permissions, Permissions.CAN_BAN_USER) ? (
                                <div className='col'>
                                    <Link
                                        className={ `btn btn-danger w-100 ${ loading ? 'disabled' : '' }` }
                                        to={ `?user=${ userID }&ban` }
                                    >
                                        Ban
                                    </Link>
                                </div>
                            ) : '' }
                        </div>
                    </div>
                </div>
            ) : '' }
        </Modal>
    ) : '';
};

export default connect(state => ({
    permissions: state.app.user.permissions.all
}))(memo(User));