import api from 'api';
import React from 'react';
import Pagination from 'components/ui/Pagination';
import formatDate from 'dateformat';
import GameStatistics from './GameStatistics';

import { useCallback, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const DEFAULT_PAGINATION = {
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    total: 0,
    from: 0,
    to: 0
};

const Crash = ({ site }) => {
    const [ pagination, setPagination ] = useState(DEFAULT_PAGINATION);
    const [ loading, setLoading ] = useState(true);
    const [ games, setGames ] = useState([]);

    const setCurrentPage = useCallback(page => {
        setLoading(true);

        api
            .getGameHistory('crash', page)
            .then(({ data, pagination }) => {
                data.forEach(game => {
                    game.wagered = Object
                        .values(game.meta.internalWagers)
                        .reduce((total, wager) => {
                            return total + wager.amount;
                        }, 0);

                    game.profit = Object
                        .values(game.meta.cashedOut)
                        .reduce((total, amount) => {
                            return total + amount;
                        }, 0);
                });

                setPagination(pagination);
                setLoading(false);
                setGames(data);
            })
            .catch(error => {
                console.warn('Failed to fetch games');
                console.error(error);

                setPagination(DEFAULT_PAGINATION);
                setLoading(false);
                setGames([]);
            });
    }, []);

    useLayoutEffect(() => {
        setCurrentPage(1);
    }, [ site ]);

    return (
        <>
            <div className='container-xl'>
                <div className='page-header'>
                    <div className='row g-2 align-items-center'>
                        <div className='col'>
                            <h2 className='page-title'>
                                Crash
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='page-body'>
                <div className='container-xl'>
                    <div className='row row-cards'>
                        <GameStatistics
                            site={ site }
                            game='crash'
                        />
                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-table table-responsive'>
                                    <table className='table table-vcenter'>
                                        <thead>
                                            <tr>
                                                <th>Round number</th>
                                                <th>Crashed at</th>
                                                <th>Wagered</th>
                                                <th>Site profit</th>
                                                <th>Game hash</th>
                                                <th>House edge</th>
                                                <th>Created at</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { games.length ? games.map(game => (
                                                <tr key={ game.roundNumber }>
                                                    <td>
                                                        <Link to={ `?game=${ game.roundNumber }` }>
                                                            { Number(game.roundNumber).toLocaleString() }
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <code>{ (game.meta.crashPoint / 100).toFixed(2) }x</code>
                                                    </td>
                                                    <td>
                                                        ${ Number(game.wagered / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }) }
                                                    </td>
                                                    <td className={ ((game.wagered - game.profit) >= 0 ) ? 'text-green' : 'text-red' }>
                                                        ${ Number((game.wagered - game.profit) / 100).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        }) }
                                                    </td>
                                                    <td>
                                                        <code>{ game.meta.gameHash }</code>
                                                    </td>
                                                    <td>
                                                        <code>{ game.meta.houseEdge * 100 }%</code>
                                                    </td>
                                                    <td>
                                                        { formatDate(game.meta.startTime, 'mmm d, hh:MM:ss tt') }
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td className='text-center' colSpan={ 7 } style={{ padding: '2rem' }}>
                                                        No entries found
                                                    </td>
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='card-footer d-flex align-items-center'>
                                    <p className='m-0 text-muted'>
                                        Showing <strong>
                                            { pagination.to ? (pagination.from + 1).toLocaleString() : 0 }
                                        </strong> to <strong>
                                            { pagination.to.toLocaleString() }
                                        </strong> of <strong>
                                            { pagination.total.toLocaleString() }
                                        </strong> entries
                                    </p>
                                    <Pagination
                                        onPageChange={ setCurrentPage }
                                        currentPage={ pagination.currentPage }
                                        totalCount={ pagination.total }
                                        pageSize={ pagination.perPage }
                                        disabled={ loading }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Crash;