import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';

const Requirements = ({ setLoading, getConfig, loading, config, show, siteConfig }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('withdrawRequirement', { defaultValue: config.withdrawRequirement / 100 });
        resetField('dailyWithdrawLimit', { defaultValue: config.dailyWithdrawLimit / 100 });
        resetField('chatRequirement', { defaultValue: config.chatRequirement / 100 });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        if((updatedConfig.withdrawRequirement * 100) !== config.withdrawRequirement)
            parameters.withdrawRequirement = updatedConfig.withdrawRequirement * 100;

        if((updatedConfig.dailyWithdrawLimit * 100) !== config.dailyWithdrawLimit)
            parameters.dailyWithdrawLimit = updatedConfig.dailyWithdrawLimit * 100;

        if((updatedConfig.chatRequirement * 100) !== config.chatRequirement)
            parameters.chatRequirement = updatedConfig.chatRequirement * 100;

        setLoading(true);

        api
            .setConfigRequirements(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    return (
        <div className={ !(show) ? 'd-none' : '' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Requirements and limits
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group mb-3 row'>
                    <label className='form-label col-3 col-form-label'>
                        Withdraw requirement
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                $
                            </span>
                            <input
                                placeholder='5'
                                className={ `form-control ps-0 ${ formState.errors.withdrawRequirement ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('withdrawRequirement', {
                                    required: true,
                                    pattern: /^\d{0,2}(\.\d{1,2})?$/,
                                    min: 0,
                                    max: 10
                                }) }
                            />
                        </div>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.withdrawRequirement?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Deposit requirement before users can withdraw their balances
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Chat requirement
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                $
                            </span>
                            <input
                                placeholder='5'
                                className={ `form-control ps-0 ${ formState.errors.chatRequirement ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('chatRequirement', {
                                    required: true,
                                    pattern: /^\d{0,2}(\.\d{1,2})?$/,
                                    min: 0,
                                    max: 10
                                }) }
                            />
                        </div>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.chatRequirement?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Deposit requirement before users can use chat
                            </small>
                        ) }
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Daily withdraw limit
                    </label>
                    <div className='col'>
                        <div className='input-group input-group-flat'>
                            <span className='input-group-text'>
                                $
                            </span>
                            <input
                                placeholder='5'
                                className={ `form-control ps-0 ${ formState.errors.dailyWithdrawLimit ? 'is-invalid is-invalid-lite' : '' }` }
                                disabled={ loading }
                                type='text'
                                { ...register('dailyWithdrawLimit', {
                                    required: true,
                                    pattern: /^\d{0,5}(\.\d{1,2})?$/,
                                    min: 0,
                                    max: 20000
                                }) }
                            />
                        </div>
                        { formState.errors?.notice?.message ? (
                            <small className='form-hint text-red'>
                                { formState.errors?.dailyWithdrawLimit?.message }
                            </small>
                        ) : (
                            <small className='form-hint'>
                                Daily withdraw limit for each user
                            </small>
                        ) }
                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(formState.isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Requirements;