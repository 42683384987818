import React, { useLayoutEffect } from 'react';
import api from 'api';

import { VirtuosoGrid } from 'react-virtuoso';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { IconPercentage, IconTrash } from '@tabler/icons';

import './CaseCreator.scss';

const DEFAULT_PAGINATION = {
    currentPage: 1,
    lastPage: 0,
    perPage: 10,
    total: 0,
    from: 0,
    to: 0
};

const CaseCreator = ({ site, items: defaultItems }) => {
    const {
        handleSubmit,
        unregister,
        formState,
        getValues,
        register,
        watch,
        reset
    } = useForm({ mode: 'onChange' });

    const itemOrder = watch('itemOrder');
    const itemName = watch('itemName');
    const caseGame = watch('caseGame');
    const caseHouseEdge = watch('caseHouseEdge');
    const allInputs = watch();

    const [ selectedItems, setSelectedItems ] = useState([]);
    const [ isLoading, setLoading ] = useState(true);
    const [ cases, setCases ] = useState({});
    const [ items, setItems ] = useState([]);
    const [ price, setPrice ] = useState(false);

    const percentage = getValues(selectedItems.map(itemID => {
        return `selectedItem-${ itemID }`;
    })).reduce((total, chance, i) => {
        return total + +(chance ?? 1);
    }, 0);

    useLayoutEffect(() => {
        if(!(defaultItems))
            return;

        let filteredItems = [ ...defaultItems[ caseGame || 'csgo' ] ];
        const sortModifier = (itemOrder || 'desc') === 'desc' ? -1 : 1;
        const lowerName = (itemName || '').toLowerCase();

        if(lowerName.length) {
            filteredItems = filteredItems
                .filter(item => item.name.toLowerCase().includes(lowerName));
        }

        filteredItems.sort((a, b) => {
            return (a.price - b.price) * sortModifier;
        });

        setItems(filteredItems);
    }, [ itemName, itemOrder, caseGame, defaultItems ]);

    useEffect(() => {
        setLoading(true);

        api.getCases().then(({ cases = {} }) => {
            setLoading(false);
            setCases(cases);
        }).catch(error => {
            console.warn('Failed to fetch system cases');
            console.error(error);
            setLoading(false);
        });
    }, [ site ]);

    useEffect(() => {
        if(selectedItems.length < 2 || !(formState.isValid) || !(formState.isDirty))
            return setPrice(false);

        const odds = getValues(selectedItems.map(itemID => {
            return `selectedItem-${ itemID }`;
        })).map(percentage => Math.ceil(percentage * 10_000)); // 1 mil / 100k

        const tickets = odds.reduce((total, tickets) => {
            return total + +tickets;
        }, 0);

        const total = selectedItems.reduce((total, itemID, index) => {
            const item = defaultItems[ caseGame ].find(item => item.itemID === +itemID);
            const adjustment = item.price * (items[ index ] / tickets);

            return total + adjustment;
        }, 0) * (1 + (caseHouseEdge / 100));

        setPrice(total);
    }, [ defaultItems, selectedItems, caseHouseEdge, allInputs ]);

    const onSubmit = state => {
        const {
            caseHouseEdge: houseEdge,
            caseCooldown: cooldown = 0,
            caseWeight: weight = 0,
            caseName: name,
            caseGame: game,
            caseMode: type,
        } = state;

        const values = selectedItems.reduce((items, itemID) => {
            items[ itemID ] = Math.ceil(state[ `selectedItem-${ itemID }` ] * 10_000); // 1 mil / 100k
            return items;
        }, {});

        setLoading(true);

        return api.createCase({
            houseEdge: +houseEdge / 100,
            cooldown: +(cooldown && cooldown.length && cooldown),
            weight: +(weight && weight.length && weight),
            image: null,
            values,
            game,
            name,
            type,
        }).then(async data => {
            await api
                .getCases()
                .then(({ cases = {} }) => setCases(cases));

            for(const itemID in selectedItems)
                unregister(`selectedItem-${ itemID }`);

            setSelectedItems([]);

            reset({
                caseHouseEdge: '',
                caseCooldown: '',
                caseWeight: '',
                caseMode: 'fixedPrice',
                caseGame: 'csgo',
                caseName: '',
            });

            window.alert(`Created case ${ name } with id ${ data.caseID }`); // eslint-disable-line no-alert
        }).catch(error => {
            console.warn('Failed to create case');
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <div className='page-body case-creator'>
            <div className='container-xl'>
                <div className='row row-deck row-cards'>
                    <div className='col-md-12'>
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='card-title'>
                                    Case creator
                                </h3>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <label className='form-label'>Name</label>
                                        <input
                                            placeholder='Case name'
                                            className={ `form-control ${ formState.errors.caseName ? 'is-invalid' : '' }` }
                                            type='text'
                                            disabled={ isLoading }
                                            { ...register('caseName', {
                                                validate: name => {
                                                    return !(Object
                                                        .values(cases)
                                                        .some(caseObj => caseObj.name.toLowerCase() === name.toLowerCase())
                                                    );
                                                },
                                                required: true,
                                                pattern: /^(?=[a-zA-Z0-9 ]{2,32}$)^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/,
                                                minLength: 3,
                                                maxLength: 32
                                            }) }
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='form-label'>Game</label>
                                        <select
                                            className={ `form-select form-control ${ formState.errors.caseGame ? 'is-invalid' : '' }` }
                                            disabled={ isLoading }
                                            { ...register('caseGame', {
                                                onChange: () => {
                                                    unregister(selectedItems.map(itemID => {
                                                        return `selectedItem-${ itemID }`;
                                                    }));

                                                    setSelectedItems([]);
                                                },
                                                required: true,
                                                value: 'csgo'
                                            }) }
                                        >
                                            <option value='csgo'>csgo</option>
                                            <option disabled value='rust'>rust</option>
                                        </select>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='form-label'>Mode</label>
                                        <select
                                            className={ `form-select form-control ${ formState.errors.caseMode ? 'is-invalid' : '' }` }
                                            disabled={ isLoading }
                                            { ...register('caseMode', {
                                                required: true,
                                                value: 'fixedPrice'
                                            }) }
                                        >
                                            <option value='fixedPrice'>items</option>
                                            <option disabled value='multipliers'>multipliers</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-md-4'>
                                        <label className='form-label'>Weight</label>
                                        <input
                                            placeholder='Case weight'
                                            className={ `form-control ${ formState.errors.caseWeight ? 'is-invalid' : '' }` }
                                            type='text'
                                            disabled={ isLoading }
                                            { ...register('caseWeight', {
                                                pattern: /^\d+$/,
                                                min: 0
                                            }) }
                                        />
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='form-label'>Cooldown</label>
                                        <div className='input-group'>
                                            <input
                                                placeholder='Case cooldown time'
                                                className={ `form-control ${ formState.errors.caseCooldown ? 'is-invalid' : '' }` }
                                                type='text'
                                                disabled={ isLoading }
                                                { ...register('caseCooldown', {
                                                    pattern: /^\d+$/,
                                                    min: 0
                                                }) }
                                            />
                                            <span className='input-group-text'>
                                                seconds
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <label className='form-label'>House edge</label>
                                        <div className='input-group'>
                                            <input
                                                placeholder='Case house edge'
                                                className={ `form-control ${ formState.errors.caseHouseEdge ? 'is-invalid' : '' }` }
                                                type='text'
                                                disabled={ isLoading }
                                                { ...register('caseHouseEdge', {
                                                    required: true,
                                                    pattern: /^\d+$/,
                                                    min: 1,
                                                    max: 15
                                                }) }
                                            />
                                            <span className='input-group-text'>
                                                <IconPercentage />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-body masonary'>
                                <div className='row' style={{ paddingBottom: '1rem' }}>
                                    <div className='col-md-4'>
                                        <input
                                            placeholder='Filter by name'
                                            className='form-control'
                                            disabled={ isLoading }
                                            type='text'
                                            { ...register('itemName') }
                                        />
                                    </div>
                                    <div className='col' />
                                    <div className='col-auto'>
                                        <select
                                            className='form-select form-control'
                                            disabled={ isLoading }
                                            { ...register('itemOrder', {
                                                value: 'desc'
                                            }) }
                                        >
                                            <option value='desc'>Price descending</option>
                                            <option value='asc'>Price ascending</option>
                                        </select>
                                    </div>
                                </div>
                                <VirtuosoGrid
                                    style={{ height: 500 }}
                                    data={ items }
                                    overscan={ 50 }
                                    itemClassName='item'
                                    listClassName='list-container'
                                    itemContent={ (index, item) => {
                                        return (
                                            <div
                                                className={ `card ${
                                                    selectedItems.includes(item.itemID)
                                                        ? 'card-active'
                                                        : selectedItems.length === 20
                                                            ? 'card-inactive'
                                                            : ''
                                                } ${
                                                    isLoading
                                                        ? 'card-inactive'
                                                        : ''
                                                }` }
                                                onClick={ () => {
                                                    if(selectedItems.includes(item.itemID)) {
                                                        unregister(`selectedItem-${ item.itemID }`);
                                                        return setSelectedItems(items => items.filter(itemID => itemID !== item.itemID));
                                                    }

                                                    if(selectedItems.length < 20)
                                                        return setSelectedItems(items => [ ...items, item.itemID ]);
                                                }}
                                            >
                                                <div className='card-body'>
                                                    <img src={ `https://ape.gg/assets/img/steam/${ item.itemID }.png` } />
                                                    <div className='meta'>
                                                        <span className='name'>
                                                            { item.weapon }<br/>
                                                            { item.skin }
                                                        </span>
                                                        <div className='price'>
                                                            ${ Number(item.price / 100).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            }) }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </div>
                            { selectedItems.length ? (
                                <div className='card-body'>
                                    <div className='row selected-items'>
                                        { selectedItems.map(itemID => {
                                            const item = defaultItems[ caseGame ]
                                                ?.find(item => item.itemID === itemID);

                                            if(!(item))
                                                return null;

                                            return (
                                                <div className='col-md-6' key={ item.itemID }>
                                                    <div className='card'>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <div className='col-auto'>
                                                                    <span className='avatar avatar-image' style={{
                                                                        backgroundImage: `url(https://ape.gg/assets/img/steam/${ item.itemID }.png)`,
                                                                        backgroundColor: 'transparent'
                                                                    }} />
                                                                </div>
                                                                <div className='col'>
                                                                    <div className='text-truncate'>
                                                                        { item.name }
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        ${ Number(item.price / 100).toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }) }
                                                                    </div>
                                                                </div>
                                                                <div className='col-auto align-self-center d-flex'>
                                                                    <div className='input-group input-group-flat'>
                                                                        <input
                                                                            type='text'
                                                                            className={ `form-control text-right ${
                                                                                formState.errors[ `selectedItem-${ item.itemID }` ] ? 'is-invalid' : ''
                                                                            }` }
                                                                            disabled={ isLoading }
                                                                            { ...register(`selectedItem-${ item.itemID }`, {
                                                                                required: true,
                                                                                pattern: /^\d{0,2}(\.\d{1,4})?$/,
                                                                                value: 1,
                                                                                min: 0.0001,
                                                                                max: 100,
                                                                            }) }
                                                                        />
                                                                        <span className={ `form-control input-group-text ${
                                                                            formState.errors[ `selectedItem-${ item.itemID }` ] ? 'is-invalid' : ''
                                                                        }` }>
                                                                            %
                                                                        </span>
                                                                    </div>
                                                                    <span
                                                                        className={ `btn btn-icon btn-danger ${
                                                                            isLoading ? 'btn-loading' : ''
                                                                        }` }
                                                                        onClick={ () => {
                                                                            unregister(`selectedItem-${ item.itemID }`);
                                                                            setSelectedItems(items => items.filter(itemID => itemID !== item.itemID));
                                                                        }}
                                                                    >
                                                                        <IconTrash />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null }
                            <div className='card-footer d-flex' style={{ justifyContent: 'flex-end' }}>
                                { percentage !== 100 ? (<div className='btn btn-ghost-danger disabled'>
                                    Total odds do not add up to 100%
                                </div>) : null }
                                <button
                                    className='btn btn-primary'
                                    disabled={ !(formState.isValid) || isLoading || !(formState.isDirty) || selectedItems.length < 2 || percentage !== 100 }
                                    onClick={ handleSubmit(onSubmit) }
                                    type='submit'
                                >
                                    Create case { price ? `worth $${ Number(price / 100).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    }) }` : ''}
                                </button>
                            </div>
                        </div>
                    </div>
                    { Object.keys(cases).length ? (
                        <div className='col-md-12'>
                            <div className='card'>
                                <div className='card-header'>
                                    <h3 className='card-title'>
                                        System cases
                                    </h3>
                                </div>
                                <div className='card-table table-responsive'>
                                    <table className='table table-vcenter'>
                                        <thead>
                                            <tr>
                                                <th>Case</th>
                                            </tr>
                                        </thead>
                                        <tbody className='placeholder-glow'>
                                            { Object.values(cases).map(caseObj => (
                                                <tr key={ caseObj.name }>
                                                    <td>
                                                        <pre>
                                                            { JSON.stringify(caseObj, null, 2) }
                                                        </pre>
                                                    </td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : null }
                </div>
            </div>
        </div>
    );
};

export default connect(state => ({
    items: state.app.items
}))(CaseCreator);