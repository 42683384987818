import api from 'api';
import React from 'react';

import { useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';

const Captchas = ({ setLoading, getConfig, loading, config, show }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register,
        watch
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        resetField('captcha', { defaultValue: JSON.parse(JSON.stringify(config?.captcha || {})) });
    }, [ config ]);

    const onSubmit = updatedConfig => {
        const parameters = {};

        for(const key in updatedConfig.captcha) {
            if(updatedConfig.captcha[ key ] !== config.captcha[ key ])
                parameters[ key ] = updatedConfig.captcha[ key ];
        }

        setLoading(true);

        api
            .setConfigCaptchas(parameters)
            .then(() => getConfig())
            .catch(error => {
                console.warn('Failed to update config');
                console.error(error);
                setLoading(false);
            });
    };

    const captcha = watch('captcha'); // Again, don't even ask.
    const isDirty = JSON.stringify(config?.captcha) !== JSON.stringify(captcha);

    return (
        <div className={ !(show) ? 'd-none' : 'captchas' }>
            <div className='card-header'>
                <h3 className='card-title'>
                    Captchas
                </h3>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Affiliate case
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.affiliateCase') }
                            />
                            <small className='form-hint'>
                                Displayed when users redeem an affiliate case
                            </small>
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Daily case
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.dailyCase') }
                            />
                            <small className='form-hint'>
                                Displayed when users redeem a daily case
                            </small>
                        </label>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        User login
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.login') }
                            />
                            <small className='form-hint'>
                                Displayed when logging in with a username + password combo
                            </small>
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        User registration
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.registration') }
                            />
                            <small className='form-hint'>
                                Displayed when registering with a username + password combo
                            </small>
                        </label>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Faucet
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.faucet') }
                            />
                            <small className='form-hint'>
                                Displayed when users redeem the faucet
                            </small>
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Free coins
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.freeCoins') }
                            />
                            <small className='form-hint'>
                                Displayed when users redeem free coins
                            </small>
                        </label>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Chat rain
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.chatRain') }
                            />
                            <small className='form-hint'>
                                Displayed when users redeem chat rain
                            </small>
                        </label>
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-3 col-form-label'>
                        Promo codes
                    </label>
                    <div className='col'>
                        <label className='form-check form-switch'>
                            <input
                                className='form-check-input'
                                disabled={ loading }
                                type='checkbox'
                                { ...register('captcha.promoCode') }
                            />
                            <small className='form-hint'>
                                Displayed when users redeem a promo code
                            </small>
                        </label>

                    </div>
                </div>
                <div className='form-footer mt-2'>
                    <button
                        className='btn btn-primary'
                        disabled={ !(formState.isValid) || loading || !(isDirty) }
                        onClick={ handleSubmit(onSubmit) }
                        type='submit'
                    >
                        Save config
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Captchas;