import api from 'api';
import React from 'react';

import { useLayoutEffect, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const Limits = ({ loading, setLoading, setError, query, setQuery, user, getUser }) => {
    const {
        handleSubmit,
        resetField,
        formState,
        register
    } = useForm({ mode: 'onChange' });

    useLayoutEffect(() => {
        if(!(user.flairs))
            return;

        resetField('requirement', { defaultValue: user[ 'withdraw:requirement' ] / 100 });
        resetField('locked', { defaultValue: user[ 'withdraw:locked' ] });
        resetField('limit', { defaultValue: user[ 'withdraw:limit' ] / 100 });
    }, [ user ]);

    const onCloseModal = useCallback(() => {
        query.delete('limits');
        setQuery(query);
    }, [ query ]);

    const onSubmit = limits => {
        setLoading(true);

        limits.requirement *= 100;
        limits.limit *= 100;

        return api
            .setUserLimits(user.userID, limits)
            .then(() => {
                return getUser();
            })
            .catch(error => {
                console.warn('Failed to set user limits');
                console.error(error);
                setError(error);
            });
    };

    return (
        <Modal
            animation={ false }
            onHide={ () => !(loading) && onCloseModal() }
            show={ true }
            centered
        >
            <div className='modal-header'>
                <h5 className='modal-title'>
                    Set limits for { user.username }
                </h5>
                { !(loading) ? (
                    <button
                        aria-label='Close'
                        className='btn-close'
                        onClick={ onCloseModal }
                        type='button'
                    />
                ) : '' }
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Daily withdraw limit
                </label>
                <div className='input-group input-group-flat'>
                    <span className='input-group-text'>
                        $
                    </span>
                    <input
                        className={ `form-control ps-0 ${ formState.errors.limit ? 'is-invalid is-invalid-lite' : '' }` }
                        disabled={ loading }
                        type='text'
                        { ...register('limit', {
                            required: true,
                            pattern: /^\d{0,5}(\.\d{1,2})?$/,
                            min: 0,
                            max: 99999
                        }) }
                    />
                </div>
            </div>
            <div className='modal-body'>
                <label className='form-label'>
                    Deposit requirement to withdraw
                </label>
                <div className='input-group input-group-flat'>
                    <span className='input-group-text'>
                        $
                    </span>
                    <input
                        className={ `form-control ps-0 ${ formState.errors.requirement ? 'is-invalid is-invalid-lite' : '' }` }
                        disabled={ loading }
                        type='text'
                        { ...register('requirement', {
                            required: true,
                            pattern: /^\d{0,5}(\.\d{1,2})?$/,
                            min: 0,
                            max: 99999
                        }) }
                    />
                </div>
            </div>
            <div className='modal-body'>
                <div className='form-selectgroup form-selectgroup-boxes d-flex flex-column'>
                    <label className='form-selectgroup-item flex-fill'>
                        <input
                            className='form-selectgroup-input'
                            disabled={ loading }
                            type='checkbox'
                            { ...register('locked') }
                        />
                        <div className='form-selectgroup-label d-flex align-items-center p-3'>
                            <div className='me-3'>
                                <span className='form-selectgroup-check' />
                            </div>
                            <div className='form-selectgroup-label-content'>
                                <div>
                                    <div className='font-weight-medium'>
                                        Withdraws locked
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>
            <div className='modal-footer'>
                <button
                    className={ `btn btn-primary ${ (loading || !(formState.isDirty) || !(formState.isValid)) ? 'disabled' : '' }` }
                    onClick={ handleSubmit(onSubmit) }
                    type='submit'
                >
                    { loading ? (
                        <span>
                            Saving<span className='animated-dots' />
                        </span>
                    ) : 'Save changes' }
                </button>
            </div>
        </Modal>
    );
};

export default Limits;